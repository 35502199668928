import React from "react";
import BloodFlowLogo from "../../Assets/BloodflowLogo.png";
import { useTranslation } from 'react-i18next';
import { ChevronRight } from 'lucide-react';

const Footer = () => {

    const { t } = useTranslation();

    return (
        <div className="relative">
          {/* Main CTA Section */}
          <div className="bg-black w-full flex flex-col items-center text-center pt-24 pb-32 px-6">
            {/* Background Effects */}
            <div className="absolute inset-0">
              <div className="absolute top-0 left-1/4 w-96 h-96 bg-red-500/5 rounded-full filter blur-3xl animate-pulse" />
              <div className="absolute bottom-0 right-1/4 w-96 h-96 bg-red-500/5 rounded-full filter blur-3xl animate-pulse delay-1000" />
            </div>
      
            {/* Content */}
            <div className="relative z-10 max-w-4xl mx-auto">
              <h3 className="text-3xl sm:text-4xl md:text-5xl font-bold mb-6">
                <span className="bg-gradient-to-r from-white to-gray-300 bg-clip-text text-transparent">
                  {t('ReadyToTransform')}
                </span>
              </h3>
              
              <p className="text-base sm:text-lg text-gray-400 mb-12 max-w-[650px] mx-auto">
                {t('ReadyToTransform2')}
              </p>
      
              <a 
                href="https://calendly.com/tiago-costa-bloodflow"
                target="_blank"
                rel="noopener noreferrer"
                className="group relative overflow-hidden bg-red-600 px-8 py-4 rounded-xl font-semibold text-white shadow-lg inline-flex items-center justify-center transition-all duration-300 hover:shadow-xl hover:-translate-y-0.5"
              >
                <div className="absolute inset-0 bg-gradient-to-r from-red-500 to-red-600 transition-transform duration-300 group-hover:scale-105" />
                <span className="relative z-10 flex items-center">
                  {t('BookDemo')}
                  <ChevronRight className="ml-2 size-4 transition-transform group-hover:translate-x-1" />
                </span>
              </a>
            </div>
          </div>
      
          {/* Footer Section */}
          <div className="bg-gradient-to-b from-black to-gray-900">
            <div className="max-w-7xl mx-auto w-full flex flex-row items-start justify-between px-6 md:px-12 lg:px-24 py-16">
              {/* Logo Column */}
              <div className="mb-8 md:mb-0">
                <img 
                  className="w-36 md:w-44 hover:opacity-90 transition-opacity" 
                  src={BloodFlowLogo} 
                  alt="BloodFlow Logo" 
                />
              </div>
      
              {/* Info Column */}
              <div className="text-gray-400 space-y-2">
                <p className="text-white font-semibold mb-4">BloodFlow</p>
                <p className="transition-colors hover:text-gray-300">{t('footerAddress')}</p>
                <p className="transition-colors hover:text-gray-300">{t('footerAddress2')}</p>
                <a 
                  href={`mailto:${t('footerEmail')}`} 
                  className="block mt-6 text-gray-400 hover:text-red-500 transition-colors"
                >
                  {t('footerEmail')}
                </a>
              </div>
            </div>
      
            {/* Copyright Bar */}
            <div className="border-t border-gray-800">
              <div className="max-w-7xl mx-auto px-6 md:px-12 lg:px-24 py-6">
                <p className="text-center text-sm text-gray-600">
                  © {new Date().getFullYear()} BloodFlow
                </p>
              </div>
            </div>
          </div>
        </div>
      );
}

export default Footer;