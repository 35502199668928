import React, { useState, useEffect } from 'react';
import {AiOutlineClose, AiOutlineMenu} from 'react-icons/ai'
import BloodFlowLogo from "../../Assets/logoBloodFlowNav.png";
import { Link as RouterLink} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ChevronRight } from 'lucide-react';

const Navbar = () => {

    const { t } = useTranslation();
    const [nav, setNav] = useState(false);

    const handleNav = () => {
        setNav(!nav);
    };

    useEffect(() => {
        const closeNavOnResize = () => {
            if (window.innerWidth > 768 && nav) {
                setNav(false);
            }
        };

        window.addEventListener('resize', closeNavOnResize);

        return () => {
            window.removeEventListener('resize', closeNavOnResize);
        };
    }, [nav]);

    return (
        <nav className="sticky top-0 z-50 bg-white/80 backdrop-blur-sm shadow-sm">
          <div className="flex justify-between items-center h-20 max-w-[1400px] mx-auto px-6 lg:px-8">
            {/* Logo */}
            <img 
              className="w-16 cursor-pointer transition-transform hover:scale-105"
              src={BloodFlowLogo}
              alt="BloodFlow Logo"
            />
      
            {/* Desktop Menu */}
            <div className='hidden lg:flex items-center space-x-6'>
              <RouterLink to="/login">
              <button className='group relative overflow-hidden bg-black px-6 rounded-xl font-semibold py-2.5 text-white shadow-lg transition-all duration-300 hover:shadow-xl hover:-translate-y-0.5'>
                <div className="absolute inset-0 bg-black transition-transform duration-300 group-hover:scale-105" />
                  <span className="relative z-10 flex items-center justify-center">
                    {t('navButton')}
                    <ChevronRight className="ml-2 size-4 transition-transform duration-300 group-hover:translate-x-1" />
                  </span>
              </button>
              </RouterLink>
            </div>
      
            {/* Mobile Menu Button */}
            <div onClick={handleNav} className='lg:hidden'>
              <button className="p-2 rounded-lg hover:bg-gray-100 transition-colors">
                {nav ? <AiOutlineClose size={24} /> : <AiOutlineMenu size={24} />}
              </button>
            </div>
      
            {/* Mobile Menu Panel - Added transparency effect */}
            <div className={`fixed inset-y-0 left-0 w-[280px] h-screen bg-white/95 backdrop-blur-sm border-r border-gray-200 transform transition-transform duration-300 ease-in-out z-50
              ${nav ? 'translate-x-0' : '-translate-x-full'}`}
            >
              <div className="h-full p-6 space-y-6">
                <img 
                  className="w-16 mx-auto mb-20"
                  src={BloodFlowLogo}
                  alt="BloodFlow Logo"
                />
                
                <div className="space-y-6">                  
                  <RouterLink to="/login">
                  <button className=' w-full group relative overflow-hidden bg-black px-6 rounded-xl font-semibold py-2.5 text-white shadow-lg transition-all duration-300 hover:shadow-xl hover:-translate-y-0.5'>
                    <div className="absolute inset-0 bg-black transition-transform duration-300 group-hover:scale-105" />
                      <span className="relative z-10 flex items-center justify-center">
                        {t('navButton')}
                        <ChevronRight className="ml-2 size-4 transition-transform duration-300 group-hover:translate-x-1" />
                      </span>
                  </button>
                  </RouterLink>
                </div>
              </div>
            </div>
          </div>
        </nav>
      );
}

export default Navbar