import React, { useState, useEffect, useMemo } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ReferenceLine
} from 'recharts';
import { ChevronDown, AlertCircle, Loader2 } from 'lucide-react';

const BiomarkerChart = ({ data, biomarkerRanges, isLoading, error }) => {
  const hasData = data && data.length > 0;
  const hasRanges = biomarkerRanges && Object.keys(biomarkerRanges).length > 0;

  const availableBiomarkers = useMemo(() => {
    if (!hasRanges) return [];
    return Object.keys(biomarkerRanges).filter(name => 
      biomarkerRanges[name] && 
      !isNaN(biomarkerRanges[name].min) && 
      !isNaN(biomarkerRanges[name].max)
    );
  }, [biomarkerRanges, hasRanges]);

  const [selectedBiomarker, setSelectedBiomarker] = useState(availableBiomarkers[0] || '');

  useEffect(() => {
    if (availableBiomarkers.length > 0 && !availableBiomarkers.includes(selectedBiomarker)) {
      setSelectedBiomarker(availableBiomarkers[0]);
    }
  }, [availableBiomarkers, selectedBiomarker]);

  const renderContent = () => {
    if (isLoading) {
      return (
        <div className="flex flex-col items-center justify-center h-full">
          <Loader2 className="w-12 h-12 text-[#ce3d3d] animate-spin" />
          <p className="mt-4 text-gray-600">Loading data...</p>
        </div>
      );
    }

    if (error) {
      return (
        <div className="flex flex-col items-center justify-center h-full text-red-500">
          <AlertCircle className="w-12 h-12 mb-4" />
          <p className="text-center">{error}</p>
        </div>
      );
    }

    if (!hasData || !hasRanges || availableBiomarkers.length === 0) {
      return (
        <div className="flex flex-col items-center justify-center h-full text-gray-500">
          <AlertCircle className="w-12 h-12 mb-4" />
          <p className="text-center">No biomarker data available</p>
        </div>
      );
    }

    const currentRange = biomarkerRanges[selectedBiomarker];
    if (!currentRange || !selectedBiomarker || isNaN(currentRange.min) || isNaN(currentRange.max)) {
      return (
        <div className="flex flex-col items-center justify-center h-full text-gray-500">
          <AlertCircle className="w-12 h-12 mb-4" />
          <p className="text-center">Please select a valid biomarker</p>
        </div>
      );
    }
    
    const validData = data.filter(d => 
      d[selectedBiomarker] !== undefined && 
      d[selectedBiomarker] !== null && 
      !isNaN(d[selectedBiomarker])
    );

    if (validData.length === 0) {
      return (
        <div className="flex flex-col items-center justify-center h-full text-gray-500">
          <AlertCircle className="w-12 h-12 mb-4" />
          <p className="text-center">No valid data available for selected biomarker</p>
        </div>
      );
    }

    const minValue = Math.min(...validData.map(d => d[selectedBiomarker]));
    const maxValue = Math.max(...validData.map(d => d[selectedBiomarker]));
    const domainMin = Math.min(currentRange.min * 0.9, minValue * 0.9);
    const domainMax = Math.max(currentRange.max * 1.1, maxValue * 1.1);

    return (
      <ResponsiveContainer width="100%" height="100%">
        <LineChart 
          data={validData} 
          margin={{ top: 10, right: 50, left: 0, bottom: 20 }}
        >
          <CartesianGrid 
            strokeDasharray="3 3" 
            stroke="#f0f0f0" 
            vertical={false} 
          />
          <XAxis 
            dataKey="date"
            tick={{ fontSize: 10, fill: '#6B7280' }}
            tickLine={{ stroke: '#6B7280' }}
            axisLine={{ stroke: '#6B7280' }}
            height={60}
            tickMargin={20}
            angle={-45}
          />
          <YAxis
            domain={[domainMin, domainMax]}
            label={{ 
              value: currentRange.unit, 
              angle: -90, 
              position: 'insideLeft',
              style: { 
                fontSize: '12px',
                fill: '#6B7280'
              }
            }}
            tick={{ 
              fontSize: 12, 
              fill: '#6B7280' 
            }}
            axisLine={{ stroke: '#6B7280' }}
            tickLine={{ stroke: '#6B7280' }}
          />
          <Tooltip
            contentStyle={{ 
              backgroundColor: 'rgba(255,255,255,0.9)', 
              border: '1px solid #E5E7EB',
              borderRadius: '8px',
              boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)'
            }}
            formatter={(value) => [
              `${value} ${currentRange.unit}`,
            ]}
            labelFormatter={(date) => `${date}`}
            labelStyle={{ 
              fontWeight: 'bold',
              color: '#374151' 
            }}
          />
          <Legend 
            wrapperStyle={{ 
              fontSize: '12px',
              color: '#6B7280'
            }} 
          />
          <ReferenceLine
            y={currentRange.min}
            stroke="#ff7300"
            strokeDasharray="3 3"
            label={{ 
              value: "Min", 
              position: "right", 
              fontSize: '12px',
              offset: 10
            }}
          />
          <ReferenceLine
            y={currentRange.max}
            stroke="#ff7300"
            strokeDasharray="3 3"
            label={{ 
              value: "Max", 
              position: "right", 
              fontSize: '12px',
              offset: 10
            }}
          />
          <Line
            type="monotone"
            dataKey={selectedBiomarker}
            stroke="#ce3d3d"
            strokeWidth={3}
            activeDot={{ 
              r: 6,
              fill: "#ce3d3d",
              stroke: 'white',
              strokeWidth: 2
            }}
            name={selectedBiomarker}
          />
        </LineChart>
      </ResponsiveContainer>
    );
  };

  return (
    <div className="w-full bg-white">
      <div className="border-b p-4">
        <div className="flex flex-col sm:flex-row justify-between items-center gap-4">
          <div className="flex items-center space-x-3">
            <h2 className="text-base md:text-xl font-bold text-black">
              Biomarker Tracking
            </h2>
          </div>
          <div className="w-full sm:w-auto relative">
            <select
              value={selectedBiomarker}
              onChange={(e) => setSelectedBiomarker(e.target.value)}
              className="
                appearance-none
                w-full sm:w-auto
                px-4 py-2
                pr-8
                text-sm
                text-gray-700
                bg-white
                border border-gray-300
                rounded-md
                cursor-pointer
              "
            >
              {availableBiomarkers.map(biomarker => (
                <option key={biomarker} value={biomarker}>
                  {biomarker}
                </option>
              ))}
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <ChevronDown className="w-4 h-4" />
            </div>
          </div>
        </div>
      </div>
      <div className="p-4 h-96 md:h-[30rem] lg:h-[36rem]">
        {renderContent()}
      </div>
    </div>
  );
};

export default BiomarkerChart;