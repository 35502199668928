import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

export const parseAnalysisText = (markdownText) => {
  if (!markdownText) return { score: null, sections: [] };

  try {
      // Enhanced score extraction
      const scorePatterns = [
          /Health Score:\s*([\d.]+)/i,                     // Health Score: 7.5
          /Health Score:\s*Score:\s*([\d.]+)/i,           // Health Score: Score: 7.5
          /Score:\s*([\d.]+)/i,                           // Score: 7.5
          /:\s*([\d.]+)/i,                           // : 7.5
          /Pontuação de Saúde:\s*([\d.]+)/i,              // Pontuação de Saúde: 7.5
          /Pontuação:\s*([\d.]+)/i,                       // Pontuação: 7.5
          /Score de Saúde:\s*([\d.]+)/i                   // Score de Saúde: 7.5
      ];

      const datePatterns = [
          /Analysis Date:\s*(\d{2}\/\d{2}\/\d{4})/i,      // Analysis Date: 16/08/2024
          /Date:\s*(\d{2}\/\d{2}\/\d{4})/i,               // Date: 16/08/2024
          /Data da Análise:\s*(\d{2}\/\d{2}\/\d{4})/i,    // Data da Análise: 16/08/2024
          /:\s*(\d{2}\/\d{2}\/\d{4})/i,                   // : 16/08/2024
          /Data:\s*(\d{2}\/\d{2}\/\d{4})/i                // Data: 16/08/2024
      ];

      const trendPatterns = [
          /Trend Indicator:\s*(?:\(?)*(→|↑|↓)\s*(\w+)(?:\))?/i,           // Trend Indicator: (→ stable)
          /Trend:\s*(?:\(?)*(→|↑|↓)\s*(\w+)(?:\))?/i,                     // Trend: ↑ improving
          /Tendência:\s*(?:\(?)*(→|↑|↓)\s*(\w+)(?:\))?/i,                 // Tendência: (↓ deteriorando)
          /Indicador de Tendência:\s*(?:\(?)*(→|↑|↓)\s*(\w+)(?:\))?/i     // Indicador de Tendência: → estável
      ];

      // Find score
      let score = null;
      for (const pattern of scorePatterns) {
          const match = markdownText.match(pattern);
          if (match) {
              score = parseFloat(match[1]);
              break;
          }
      }

      // Find date
      let date = '';
      for (const pattern of datePatterns) {
          const match = markdownText.match(pattern);
          if (match) {
              date = match[1];
              break;
          }
      }

      // Find trend
      let trend = '→';
      let trendText = 'stable';
      for (const pattern of trendPatterns) {
          const match = markdownText.match(pattern);
          if (match) {
              trend = match[1];
              const direction = match[2].toLowerCase();
              
              // Map trend words in both languages
              const trendMap = {
                  'stable': 'stable',
                  'estável': 'stable',
                  'improving': 'improving',
                  'melhorando': 'improving',
                  'deteriorating': 'deteriorating',
                  'piorando': 'deteriorating',
                  'deteriorando': 'deteriorating'
              };

              trendText = trendMap[direction] || direction;
              break;
          }
      }

      const scoreData = {
          score: score,
          date: date,
          trend: trend,
          trendText: trendText
      };

      // Enhanced section parsing to handle both formats
      const sections = [];
      const sectionRegex = /(?:\*\*(\d+)\.\s+([^*]+)\*\*|\b(\d+)\.\s+\*\*([^*]+)\*\*)/g;
      let match;
      let lastMatchEnd = 0;

      while ((match = sectionRegex.exec(markdownText)) !== null) {
          const sectionNumber = match[1] || match[3];
          const sectionTitle = (match[2] || match[4]).replace(/^[^a-zA-Z]+/, '').trim();
          const matchEnd = match.index + match[0].length;

          if (sections.length > 0) {
              const previousSection = sections[sections.length - 1];
              previousSection.content = markdownText
                  .slice(lastMatchEnd, match.index)
                  .trim()
                  .replace(/^\s*-\s*/, '');
          }

          if (sectionNumber !== '1') {
              sections.push({
                  id: sectionNumber,
                  title: sectionTitle,
                  content: ''
              });
          }

          lastMatchEnd = matchEnd;
      }

      if (sections.length > 0) {
          const lastSection = sections[sections.length - 1];
          lastSection.content = markdownText
              .slice(lastMatchEnd)
              .trim()
              .replace(/^\s*-\s*/, '');
      }

      sections.forEach(section => {
          section.content = section.content
              .replace(/\*\*([A-Z])\.\s+([^*]+)\*\*/g, '### $1. $2')
              .trim();
      });

      return { score: scoreData, sections };
  } catch (error) {
      console.error('Error parsing analysis text:', error);
      return { score: null, sections: [] };
  }
};

// Component to display sections
const AnalysisSection = ({ title, content, isOpen, onToggle, sectionNumber }) => {
  return (
      <div className="mb-4 border border-gray-100 rounded-xl overflow-hidden hover:border-red-100 transition-all duration-300">
          <button
              onClick={onToggle}
              className="w-full px-6 py-4 flex items-center justify-between bg-white hover:bg-red-50/30 transition-colors duration-300"
          >
              <div className="flex items-center space-x-3">
                  <span className="text-[#ce3d3d] font-medium">{sectionNumber}.</span>
                  <h2 className="text-base md:text-lg font-semibold text-gray-800">{title}</h2>
              </div>
              <svg
                  className={`w-5 h-5 text-gray-500 transform transition-transform duration-300 ${
                      isOpen ? 'rotate-180' : ''
                  }`}
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
              >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
              </svg>
          </button>

          <div 
              className={`transition-all duration-300 origin-top ${
                  isOpen ? 'scale-y-100 h-auto' : 'scale-y-0 h-0'
              }`}
          >
              <div className="px-6 py-4 bg-gradient-to-r from-red-50/50 to-white">
                  <div className="prose prose-lg max-w-none">
                      <ReactMarkdown
                          remarkPlugins={[remarkGfm]}
                          components={{
                              p: ({children}) => (
                                  <p className="text-gray-700 leading-relaxed mb-6 break-words whitespace-normal">{children}</p>
                              ),
                              strong: ({children}) => (
                                  <strong className="text-[#ce3d3d] font-semibold">{children}</strong>
                              ),
                              ul: ({children}) => (
                                  <ul className="list-disc pl-5 space-y-2 text-gray-700 mb-6">{children}</ul>
                              ),
                              li: ({children}) => (
                                  <li className="text-gray-700 mb-2 whitespace-normal">{children}</li>
                              ),
                              h3: ({children}) => (
                                  <h3 className="text-[#ce3d3d] font-semibold text-lg mt-6 mb-4">{children}</h3>
                              )
                          }}
                      >
                          {content}
                      </ReactMarkdown>
                  </div>
              </div>
          </div>
      </div>
  );
};

export default AnalysisSection;