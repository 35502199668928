import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ChevronDown, ChevronRight } from 'lucide-react';

const FAQSection = () => {
    const { t } = useTranslation();

    const faqs = [
        {
            question: t('Question1'),
            answer: t('Answer1')
        },
        {
            question: t('Question2'),
            answer: t('Answer2')
        },
        {
            question: t('Question3'),
            answer: t('Answer3')
        },
        {
            question: t('Question4'),
            answer: t('Answer4')
        },
        {
            question: t('Question5'),
            answer: t('Answer5')
        },
        {
          question: t('Question6'),
          answer: t('Answer6')
        },
        {
          question: t('Question7'),
          answer: t('Answer7')
        }
    ];

    const [openIndex, setOpenIndex] = useState(null);

    const toggleAccordion = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    return (
        <div className="bg-black py-32 px-6 md:px-12 lg:px-24 text-center -mt-16 relative">
          {/* Background Effects */}
          <div className="absolute inset-0">
            <div className="absolute top-0 left-1/4 w-96 h-96 bg-red-500/5 rounded-full filter blur-3xl animate-pulse" />
            <div className="absolute bottom-0 right-1/4 w-96 h-96 bg-red-500/5 rounded-full filter blur-3xl animate-pulse delay-1000" />
          </div>
      
          {/* Content */}
          <div className="relative z-10">
            <h2 className="text-4xl sm:text-5xl font-bold mb-16">
              <span className="bg-gradient-to-r from-white to-gray-400 bg-clip-text text-transparent">
                FAQs
              </span>
            </h2>
      
            <div className="max-w-3xl mx-auto space-y-4 text-left">
              {faqs.map((faq, index) => (
                <div 
                  key={index} 
                  className="group bg-gray-900/50 backdrop-blur-sm rounded-xl overflow-hidden border border-gray-800 hover:border-red-500/30 transition-all duration-300"
                >
                  <button 
                    onClick={() => toggleAccordion(index)}
                    className="w-full flex justify-between items-center p-6 text-white text-sm sm:text-base font-medium focus:outline-none transition-all group-hover:text-red-50"
                  >
                    <span className="flex-1 text-left pr-4">{faq.question}</span>
                    <span 
                      className={`flex-shrink-0 w-6 h-6 rounded-full border border-gray-700 group-hover:border-red-500/30 flex items-center justify-center transition-all duration-300 ${
                        openIndex === index ? 'bg-red-500/10 rotate-180' : ''
                      }`}
                    >
                      <ChevronDown 
                        className={`w-4 h-4 text-gray-400 group-hover:text-red-400 transition-transform duration-300 ${
                          openIndex === index ? 'transform rotate-180' : ''
                        }`} 
                      />
                    </span>
                  </button>
                  
                  <div 
                    className={`transition-all duration-300 ease-in-out overflow-hidden ${
                      openIndex === index ? 'max-h-96 opacity-100' : 'max-h-0 opacity-0'
                    }`}
                  >
                    <div className="p-6 pt-0 text-gray-300 text-sm sm:text-base">
                      <div className="border-t border-gray-800 pt-4">
                        {faq.answer}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
      
            {/* CTA Button */}
            <div className="mt-16">
              <RouterLink to="/signup">
                <button className="group relative overflow-hidden bg-red-600 px-8 py-4 rounded-xl font-semibold text-white shadow-lg transition-all duration-300 hover:shadow-xl hover:-translate-y-0.5">
                  <div className="absolute inset-0 bg-gradient-to-r from-red-500 to-red-600 transition-transform duration-300 group-hover:scale-105" />
                  <span className="relative z-10 flex items-center justify-center">
                    {t('buttonHero2')}
                    <ChevronRight className="ml-2 size-4 transition-transform group-hover:translate-x-1" />
                  </span>
                </button>
              </RouterLink>
            </div>
          </div>
        </div>
      );
};

export default FAQSection;