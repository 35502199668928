import React, {useState} from 'react';
import BloodFlowLogo from "../../Assets/logoBloodFlowNav.png";
import { useNavigate } from 'react-router-dom';
import { sendPasswordResetEmail } from 'firebase/auth';
import {auth} from "../../firebase";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { ChevronRight } from "lucide-react";

function ForgotPassword() {

    const navigate = useNavigate();
    const [email, setEmail] = useState(''); 
    const [loading, setLoading] = useState(false);

    const { t } = useTranslation();

    const sendEmail = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            sendPasswordResetEmail(auth, email);
            toast.success(t('emailSent'));
            navigate("/login");
        } catch (error) {
            setLoading(false);
            toast.error(t('emailError'));
        }
    };

    const backToHome = () => {
        navigate("/");
    };

    return (
        <div className="min-h-screen bg-gradient-to-br from-red-50 to-white flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
          <div className="max-w-md w-full space-y-8 bg-white rounded-2xl shadow-xl p-8">
            {/* Logo */}
            <div>
              <img
                onClick={backToHome}
                className="mx-auto h-16 w-auto cursor-pointer hover:opacity-90 transition-opacity"
                src={BloodFlowLogo}
                alt="BloodFlow Logo"
              />
              <h1 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                {t('forgotMyPassword')}
              </h1>
            </div>
      
            {/* Form */}
            <form onSubmit={sendEmail} className="mt-8 space-y-6">
              <div>
                <label htmlFor="email" className="sr-only">
                  Email
                </label>
                <div className="relative">
                  <input
                    id="email"
                    type="email"
                    placeholder="Email"
                    value={email}
                    required
                    onChange={(e) => setEmail(e.target.value)}
                    className="appearance-none relative block w-full px-4 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-xl focus:outline-none focus:ring-2 focus:ring-red-500 focus:border-transparent transition-all duration-200"
                  />
                </div>
              </div>
      
              <div>
                <button
                  type="submit"
                  className="group relative w-full flex justify-center py-3 px-4 border border-transparent text-base font-medium rounded-xl text-white shadow-lg transition-all duration-300 hover:shadow-xl hover:-translate-y-0.5"
                >
                  <div className="absolute inset-0 bg-gradient-to-r from-red-500 to-red-600 rounded-xl transition-transform duration-300 group-hover:scale-105" />
                  <span className="relative z-10 flex items-center justify-center">
                    {loading ? t('sendingEmail') : t('sendEmail')}
                    {!loading && (
                      <ChevronRight className="ml-2 size-4 transition-transform group-hover:translate-x-1" />
                    )}
                  </span>
                </button>
              </div>
      
              {/* Optional: Back to Login Link */}
              <div className="text-center">
                <RouterLink 
                  to="/login"
                  className="font-medium text-sm text-gray-600 hover:text-gray-500 transition-colors duration-200"
                >
                  ← {t('backToLogin')}
                </RouterLink>
              </div>
            </form>
          </div>
        </div>
      );
}

export default ForgotPassword;