import React from "react";
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import wslisbon from "../../Assets/wslisbon.jpg";
import SLUX from "../../Assets/SULx_01.png";
import UFLX from "../../Assets/uflx.jpg";
import wsimpact from "../../Assets/wsImpactNew.png";
import { ChevronRight, Check, X } from 'lucide-react';
import CaseStudyPreview from './CaseStudyPreview';
import { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { getAuth } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const Services = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isAnnual, setIsAnnual] = useState(true);
    const [loading, setLoading] = useState(false);
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
    const BASE_URL = process.env.REACT_APP_BASE_URL;

    useEffect(() => {
        if (window.location.hash === '#pricing') {
            const element = document.getElementById('pricing');
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, []);

    const prices = {
      monthly: {
          amount: 15,
          currency: 'usd',
          stripeId: 'price_monthly_id'
      },
      annual: {
          amount: 100,
          currency: 'usd',
          stripeId: 'price_annual_id'
      }
    };
    
    const currentPriceConfig = isAnnual ? prices.annual : prices.monthly;
    
    const saving = Math.round(((prices.monthly.amount * 12 - prices.annual.amount) / (prices.monthly.amount * 12)) * 100);

    const handleSubscribe = async (isAnnual) => {
      setLoading(true);

      const auth = getAuth();
      const user = auth.currentUser;
  
      if (!user) {
          // Create URL search params with plan data
          const planData = {
              planType: isAnnual ? 'annual' : 'monthly',
              priceId: isAnnual ? 
                  process.env.REACT_APP_STRIPE_YEARLY_PRICE_ID : 
                  process.env.REACT_APP_STRIPE_MONTHLY_PRICE_ID,
              price: isAnnual ? prices.annual.amount : prices.monthly.amount
          };
  
          // Navigate to signup with plan data in URL
          navigate('/signup', { 
            state: { planData } 
          });
          return;
      }
  
      try {
          const idToken = await user.getIdToken();
          const subscriptionStatus = await fetch(`${BASE_URL}/subscription/status/${user.uid}`, {
              headers: {
                  'Authorization': `Bearer ${idToken}`
              }
          });
          const { status } = await subscriptionStatus.json();

          if (status === 'pro') {
              setLoading(false);
              toast.info('You already have an active subscription!');
              return;
          }

          const stripe = await stripePromise;
              
          const response = await fetch(`${BASE_URL}/subscription/create-checkout`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${idToken}`
              },
              body: JSON.stringify({
                  priceId: isAnnual ? 
                      process.env.REACT_APP_STRIPE_YEARLY_PRICE_ID : 
                      process.env.REACT_APP_STRIPE_MONTHLY_PRICE_ID,
                      email: user.email, 
                      userId: user.uid
                  })
              });
  
          const { sessionId } = await response.json();
          
          const result = await stripe.redirectToCheckout({ sessionId });
  
          if (result.error) {
              setLoading(false);
          }
      } catch (error) {
          setLoading(false);
      } finally {
          setLoading(false);
      }
  };

    return (
        <div id="Services" className='w-full bg-gradient-to-b from-white via-red-50/10 to-white py-16 overflow-x-hidden'>
          <div className="w-full py-16 overflow-hidden">
            <h1 className="text-2xl sm:text-3xl font-bold text-center mb-16">
                <span className="relative inline-block">
                <span className="relative z-10 text-black">
                    🚀 {t('backed')} 🚀
                </span>
                </span>
            </h1>

            <div className="relative max-w-[1240px] mx-auto overflow-hidden">
                {/* Carousel track */}
                <div className="flex animate-carousel whitespace-nowrap">
                  {/* First set of logos */}
                  <div className="flex items-center justify-around min-w-full">
                    <a 
                      href="https://www.startuplisboa.com/" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="inline-block px-2 sm:px-8"
                    >
                      <img 
                        className='w-[80px] sm:w-[130px] h-[50px] sm:h-[80px] object-contain grayscale hover:grayscale-0 transition-all duration-500' 
                        src={SLUX} 
                        alt="Startup Lisboa" 
                      />
                    </a>
                    <a 
                      href="https://unicornfactorylisboa.com/" 
                      target="_blank" 
                      rel="noopener noreferrer"
                      className="inline-block px-2 sm:px-8"
                    >
                      <img 
                        className='w-[150px] sm:w-[220px] h-auto object-contain grayscale hover:grayscale-0 transition-all duration-500' 
                        src={UFLX} 
                        alt="Unicorn Factory" 
                      />
                    </a>
                    <a 
                      href="https://websummit.com/appearances/lis24/9d50ce9f-6b8f-41a9-a7aa-b4a6afa4e0df/bloodflow/" 
                      target="_blank" 
                      rel="noopener noreferrer"
                      className="inline-block px-2 sm:px-8"
                    >
                      <img 
                        className='w-[100px] sm:w-[150px] h-auto object-contain grayscale hover:grayscale-0 transition-all duration-500' 
                        src={wslisbon} 
                        alt="Web Summit Lisbon" 
                      />
                    </a>
                    <a 
                      href="https://websummit.com/appearances/lis24/9d50ce9f-6b8f-41a9-a7aa-b4a6afa4e0df/bloodflow/" 
                      target="_blank" 
                      rel="noopener noreferrer"
                      className="inline-block px-2 sm:px-8"
                    >
                      <img 
                        className='w-[100px] sm:w-[150px] h-auto object-contain grayscale hover:grayscale-0 transition-all duration-500' 
                        src={wsimpact} 
                        alt="Web Summit Impact" 
                      />
                    </a>
                  </div>

                  {/* Duplicate set for seamless loop */}
                  <div className="flex items-center justify-around min-w-full">
                    <a 
                      href="https://www.startuplisboa.com/" 
                      target="_blank" 
                      rel="noopener noreferrer"
                      className="inline-block px-2 sm:px-8"
                    >
                      <img 
                        className='w-[80px] sm:w-[130px] h-[50px] sm:h-[80px] object-contain grayscale hover:grayscale-0 transition-all duration-500' 
                        src={SLUX} 
                        alt="Startup Lisboa" 
                      />
                    </a>
                    <a 
                      href="https://unicornfactorylisboa.com/" 
                      target="_blank" 
                      rel="noopener noreferrer"
                      className="inline-block px-2 sm:px-8"
                    >
                      <img 
                        className='w-[150px] sm:w-[220px] h-auto object-contain grayscale hover:grayscale-0 transition-all duration-500' 
                        src={UFLX} 
                        alt="Unicorn Factory" 
                      />
                    </a>
                    <a 
                      href="https://websummit.com/appearances/lis24/9d50ce9f-6b8f-41a9-a7aa-b4a6afa4e0df/bloodflow/" 
                      target="_blank" 
                      rel="noopener noreferrer"
                      className="inline-block px-2 sm:px-8"
                    >
                      <img 
                        className='w-[100px] sm:w-[150px] h-auto object-contain grayscale hover:grayscale-0 transition-all duration-500' 
                        src={wslisbon} 
                        alt="Web Summit Lisbon" 
                      />
                    </a>
                    <a 
                      href="https://websummit.com/appearances/lis24/9d50ce9f-6b8f-41a9-a7aa-b4a6afa4e0df/bloodflow/" 
                      target="_blank" 
                      rel="noopener noreferrer"
                      className="inline-block px-2 sm:px-8"
                    >
                      <img 
                        className='w-[100px] sm:w-[150px] h-auto object-contain grayscale hover:grayscale-0 transition-all duration-500' 
                        src={wsimpact} 
                        alt="Web Summit Impact" 
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
      
          <h1 className="text-center text-2xl sm:text-4xl font-bold bg-gradient-to-r from-red-600 to-red-500 bg-clip-text text-transparent">{t('sabeMaisServices')}</h1>
          <h2 className="text-gray-800 font-bold text-center text-md sm:text-xl mb-16 sm:mb-12 mt-6 sm:mt-4">{t('sabeMaisServices2')}</h2>
      
          <div className="max-w-[1240px] mx-auto grid grid-cols-1 sm:grid-cols-3 gap-6 p-6 mb-16">
            {[1, 2, 3].map((cardNumber) => (
              <div key={cardNumber} className="relative group hover:translate-y-0 hover:translate-x-0">
                {/* Black shadow background */}
                <div className="absolute inset-0 bg-gradient-to-r from-gray-800 to-gray-700 rounded-xl shadow-lg transform transition-transform duration-300 translate-x-0 translate-y-0 group-hover:translate-x-2 group-hover:translate-y-2"></div>
                {/* Red card content */}
                <div className="relative bg-gradient-to-br from-red-600 to-red-500 p-6 rounded-xl shadow-xl transform transition-all duration-300 translate-x-0 translate-y-0 group-hover:-translate-y-1 group-hover:-translate-x-1">
                  <p className="text-white/90 font-medium">
                    <span className="font-bold block mb-2">{t(`card${cardNumber}Prob`)}</span>
                    {t(`card${cardNumber}ProbText`)}
                  </p>
                </div>
              </div>
            ))}
          </div>
      
          <div className="max-w-[950px] mx-auto py-12 px-6">
            <h2 className="text-center text-2xl sm:text-4xl font-bold bg-gradient-to-r from-red-600 to-red-500 bg-clip-text text-transparent mb-16">{t('Solution')}</h2>
      
            <div className="grid mt-16 sm:grid-cols-1 gap-10 sm:mt-10">
              <div className="flex flex-col sm:flex-row items-center mb-24 sm:mb-32 group">
                <div className="w-32 h-32 md:w-52 md:h-52 bg-gradient-to-br from-red-400 to-red-300 rounded-2xl mb-4 sm:mb-0 sm:mr-12 flex items-center justify-center flex-shrink-0 shadow-lg transform transition-transform group-hover:scale-105">
                  <span className="text-2xl font-bold text-white">1</span>
                </div>
                <div className="text-center sm:text-left">
                  <h3 className="text-2xl sm:text-4xl font-semibold mb-6">{t('card1Sol')}</h3>
                  <p className="text-gray-600 text-lg sm:text-xl">
                    {t('card1SolText')}
                  </p>
                  <a href="https://calendly.com/tiago-costa-bloodflow" 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    className="group inline-flex items-center text-red-500 text-lg sm:text-xl mt-6 hover:text-red-600 transition-colors">
                    {t('BookDemo')}
                    <ChevronRight className="ml-2 size-4 transition-transform group-hover:translate-x-1" />
                  </a>
                </div>
              </div>
      
              <div className="flex flex-col sm:flex-row-reverse items-center mb-24 sm:mb-32 group">
                <div className="w-32 h-32 md:w-52 md:h-52 bg-gradient-to-br from-red-400 to-red-300 rounded-2xl mb-4 sm:mb-0 sm:ml-12 flex items-center justify-center flex-shrink-0 shadow-lg transform transition-transform group-hover:scale-105">
                  <span className="text-2xl font-bold text-white">2</span>
                </div>
                <div className="text-center sm:text-left">
                  <h3 className="text-2xl sm:text-4xl font-semibold mb-6">{t('card2Sol')}</h3>
                  <p className="text-gray-600 text-lg sm:text-xl">
                    {t('card2SolText')}
                  </p>
                  <RouterLink to="/signup">
                    <button className='group relative overflow-hidden bg-red-600 px-6 py-3 rounded-xl font-semibold text-white shadow-lg transition-all duration-300 hover:shadow-xl hover:-translate-y-0.5 mt-6'>
                      <div className="absolute inset-0 bg-gradient-to-r from-red-500 to-red-600 transition-transform duration-300 group-hover:scale-105" />
                      <span className="relative z-10 flex items-center justify-center">
                        {t('buttonHero2')}
                        <ChevronRight className="ml-2 size-4 transition-transform group-hover:translate-x-1" />
                      </span>
                    </button>
                  </RouterLink>
                </div>
              </div>
      
              <div className="flex flex-col sm:flex-row items-center mb-24 sm:mb-32 group">
                <div className="w-32 h-32 md:w-52 md:h-52 bg-gradient-to-br from-red-400 to-red-300 rounded-2xl mb-4 sm:mb-0 sm:mr-12 flex items-center justify-center flex-shrink-0 shadow-lg transform transition-transform group-hover:scale-105">
                  <span className="text-2xl font-bold text-white">3</span>
                </div>
                <div className="text-center sm:text-left">
                  <h3 className="text-2xl sm:text-4xl font-semibold mb-6">{t('card3Sol')}</h3>
                  <p className="text-gray-600 text-lg sm:text-xl">
                    {t('card3SolText')}
                  </p>
                  <RouterLink to="/signup">
                    <button className='group relative overflow-hidden bg-red-600 px-6 py-3 rounded-xl font-semibold text-white shadow-lg transition-all duration-300 hover:shadow-xl hover:-translate-y-0.5 mt-6'>
                      <div className="absolute inset-0 bg-gradient-to-r from-red-500 to-red-600 transition-transform duration-300 group-hover:scale-105" />
                      <span className="relative z-10 flex items-center justify-center">
                        {t('buttonHero2')}
                        <ChevronRight className="ml-2 size-4 transition-transform group-hover:translate-x-1" />
                      </span>
                    </button>
                  </RouterLink>
                </div>
              </div>
      
              <div className="flex flex-col sm:flex-row-reverse items-center mb-24 sm:mb-32 group">
                <div className="w-32 h-32 md:w-52 md:h-52 bg-gradient-to-br from-red-400 to-red-300 rounded-2xl mb-4 sm:mb-0 sm:ml-12 flex items-center justify-center flex-shrink-0 shadow-lg transform transition-transform group-hover:scale-105">
                  <span className="text-2xl font-bold text-white">4</span>
                </div>
                <div className="text-center sm:text-left">
                  <h3 className="text-2xl sm:text-4xl font-semibold mb-6">{t('card4Sol')}</h3>
                  <p className="text-gray-600 text-lg sm:text-xl">
                    {t('card4SolText')}
                  </p>
                  <RouterLink to="/signup">
                    <button className='group relative overflow-hidden bg-red-600 px-6 py-3 rounded-xl font-semibold text-white shadow-lg transition-all duration-300 hover:shadow-xl hover:-translate-y-0.5 mt-6'>
                      <div className="absolute inset-0 bg-gradient-to-r from-red-500 to-red-600 transition-transform duration-300 group-hover:scale-105" />
                      <span className="relative z-10 flex items-center justify-center">
                        {t('buttonHero2')}
                        <ChevronRight className="ml-2 size-4 transition-transform group-hover:translate-x-1" />
                      </span>
                    </button>
                  </RouterLink>
                </div>
              </div>
            </div>
          </div>

          <CaseStudyPreview />
      
            <div className="relative">
                {/* Savings Section */}
                <div className="bg-gradient-to-b from-gray-900 to-black pt-32 pb-24 px-6 md:px-12 lg:px-24 text-center rounded-t-3xl shadow-2xl relative overflow-hidden">
                    {/* Background Effects */}
                    <div className="absolute inset-0">
                        <div className="absolute top-0 left-1/4 w-96 h-96 bg-red-500/10 rounded-full filter blur-3xl animate-pulse" />
                            <div className="absolute bottom-0 right-1/4 w-96 h-96 bg-red-500/10 rounded-full filter blur-3xl animate-pulse delay-1000" />
                        </div>

                        <div className="relative z-10">
                        <h2 className="text-3xl sm:text-5xl font-bold mb-16">
                            <span className="bg-gradient-to-r from-white to-gray-400 bg-clip-text text-transparent">
                            {t('AnuallSav')}
                            </span>
                        </h2>

                        <div className="flex flex-col md:flex-row items-center justify-center gap-12">
                            {/* Savings Card */}
                            <div className="group flex flex-col items-center bg-gray-800/40 backdrop-blur-sm rounded-2xl p-8 md:w-1/3 shadow-2xl border border-gray-700/50 hover:border-red-500/50 transition-all duration-500">
                            <span className="text-6xl font-bold bg-gradient-to-r from-green-400 to-green-600 bg-clip-text text-transparent mb-2 group-hover:scale-110 transition-transform duration-300">
                                100x Faster
                            </span>
                            <p className="mt-4 text-lg text-gray-300 group-hover:text-white transition-colors duration-300">
                                {t('AnuallSavText')}
                            </p>
                            </div>

                            {/* Text Content */}
                            <div className="flex-1 text-center md:text-left">
                                <p className="text-lg sm:text-xl text-gray-400">
                                    {t('AnuallSavText2')}
                                </p>
                                <a 
                                    href="https://calendly.com/tiago-costa-bloodflow"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="group inline-flex items-center text-red-500 text-lg sm:text-xl mt-8 hover:text-red-400 transition-all duration-300">
                                    <span className="relative">
                                    {t('BookDemo')}
                                    <span className="absolute bottom-0 left-0 w-full h-0.5 bg-red-500 scale-x-0 group-hover:scale-x-100 transition-transform duration-300" />
                                    </span>
                                    <ChevronRight className="ml-2 size-4 transition-transform group-hover:translate-x-2" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="pricing" className="bg-black py-24 px-6 md:px-12 lg:px-24">
                      {/* Header */}
                      <div className="text-center mb-16">
                          <h2 className="text-4xl sm:text-5xl font-bold mb-6">
                              <span className="bg-gradient-to-r from-white to-gray-400 bg-clip-text text-transparent">
                                  {t('TailoredPrice')}
                              </span>
                          </h2>
                          <p className="text-gray-400 text-lg max-w-2xl mx-auto">
                              {t('TailoredPriceText')}
                          </p>
                      </div>

                      {/* Toggle Monthly/Annual */}
                      <div className="flex items-center justify-center gap-4 mb-12">
                        <span className={`text-gray-400 ${!isAnnual && 'text-white font-semibold'}`}>{t('monthly')}</span>
                        <button 
                            onClick={() => setIsAnnual(!isAnnual)}
                            className="relative w-16 h-8 rounded-full bg-gray-700 transition-colors duration-300"
                        >
                            <div className={`absolute left-1 top-1 w-6 h-6 rounded-full bg-red-500 transition-transform duration-300 transform ${isAnnual ? 'translate-x-8' : 'translate-x-0'}`} />
                        </button>
                        <span className={`text-gray-400 ${isAnnual && 'text-white font-semibold'}`}>
                              {t('yearly')}
                            <span className="ml-2 text-sm text-red-500 font-normal">{t('savings')} {saving}%</span>
                        </span>
                    </div>

                      {/* Pricing Cards */}
                      <div className="flex flex-col lg:flex-row gap-8 max-w-6xl mx-auto">
                          {/* Free Tier */}
                          <div className="flex-1 bg-gray-800/40 backdrop-blur-sm rounded-2xl p-8 border border-gray-700/50 hover:border-red-500/50 transition-all duration-500 flex flex-col">
                              <div className="flex-grow">
                                  <div className="text-center mb-8">
                                      <h3 className="text-2xl font-bold text-white mb-4">{t('freetrial')}</h3>
                                      <div className="mb-4">
                                          <span className="text-4xl font-bold text-white">$0</span>
                                          <span className="text-gray-400">{t('forever')}</span>
                                      </div>
                                      <p className="text-gray-400">{t('perfectToTry')}</p>
                                  </div>

                                  <ul className="space-y-4 mb-8">
                                      <li className="flex items-center text-gray-300">
                                          <Check className="w-5 h-5 text-green-500 mr-3" />
                                          {t('5analysis')}
                                      </li>
                                      <li className="flex items-center text-gray-300">
                                          <Check className="w-5 h-5 text-green-500 mr-3" />
                                          {t('advHealth')}
                                      </li>
                                      <li className="flex items-center text-gray-300">
                                          <Check className="w-5 h-5 text-green-500 mr-3" />
                                          {t('longitudinal')}
                                      </li>
                                      <li className="flex items-center text-gray-300">
                                          <Check className="w-5 h-5 text-green-500 mr-3" />
                                          {t('BioMarkers')}
                                      </li>
                                      <li className="flex items-center text-gray-300">
                                          <X className="w-5 h-5 text-red-500 mr-3" />
                                          {t('priority')}
                                      </li>
                                  </ul>
                              </div>
                              <RouterLink to="/signup">
                                  <button
                                      className="w-full py-3 px-6 rounded-xl bg-gray-700 text-white hover:bg-gray-600 transition-colors duration-300">
                                      {t('startFree')}  
                                  </button>
                              </RouterLink>
                          </div>

                          {/* Pro Tier */}
                          <div className="flex-1 bg-gradient-to-b from-red-500/10 to-black rounded-2xl p-8 border border-red-500/50 hover:border-red-400 transition-all duration-500 relative flex flex-col">
                              {/* Popular Badge */}
                              <div className="absolute -top-4 left-1/2 -translate-x-1/2 bg-red-500 text-white px-4 py-1 rounded-full text-sm font-semibold">
                              {t('mostPopular')}
                              </div>
                              <div className="flex-grow">
                                  <div className="text-center mb-8">
                                    <h3 className="text-2xl font-bold text-white mb-4">{t('proAccess')}</h3>
                                    <div className="mb-4">
                                        <span className="text-4xl font-bold text-white">
                                            ${currentPriceConfig.amount}
                                        </span>
                                        <span className="text-gray-400">/{isAnnual ? 'year' : 'month'}</span>
                                        <div className="text-sm text-red-400 mt-2">
                                            {isAnnual ? 
                                                t('save44') : 
                                                t('yearlyVsMonthly')
                                            }
                                        </div>
                                    </div>
                                      <p className="text-gray-400">{t('everyThingYouNeed')}</p>
                                  </div>

                                  <ul className="space-y-4 mb-8">
                                      <li className="flex items-center text-gray-300">
                                          <Check className="w-5 h-5 text-green-500 mr-3" />
                                          {t('unlimitedAnalysis')}
                                      </li>
                                      <li className="flex items-center text-gray-300">
                                          <Check className="w-5 h-5 text-green-500 mr-3" />
                                          {t('advHealth')}
                                      </li>
                                      <li className="flex items-center text-gray-300">
                                          <Check className="w-5 h-5 text-green-500 mr-3" />
                                          {t('longitudinal')}
                                      </li>
                                      <li className="flex items-center text-gray-300">
                                          <Check className="w-5 h-5 text-green-500 mr-3" />
                                          {t('BioMarkers')}
                                      </li>
                                      <li className="flex items-center text-gray-300">
                                          <Check className="w-5 h-5 text-green-500 mr-3" />
                                          {t('priority')}
                                      </li>
                                  </ul>
                              </div>
                              <button 
                                  onClick={() => handleSubscribe(isAnnual)}
                                  disabled={loading}
                                  className="group relative w-full py-3 px-6 rounded-xl bg-red-500 text-white hover:bg-red-600 transition-colors duration-300 disabled:opacity-50"
                              >
                                  <div className="absolute inset-0 bg-gradient-to-r from-red-500 to-red-600 rounded-xl transition-transform duration-300 group-hover:scale-105" />
                                  <span className="relative z-10 flex items-center justify-center">
                                      {loading ? (
                                          <div className="w-5 h-5 border-2 border-white/30 border-t-white rounded-full animate-spin" />
                                      ) : (
                                          <>
                                              {t('getProAccess')}
                                              <ChevronRight className="ml-2 size-4 transition-transform group-hover:translate-x-1" />
                                          </>
                                      )}
                                  </span>
                              </button>
                          </div>
                      </div>

                      {/* Trust Indicators */}
                      <div className="mt-16 text-center">
                          <p className="text-gray-400 mb-4">{t('trustedByHealth')}</p>
                          <div className="flex justify-center gap-2">
                              <span className="text-yellow-400">★</span>
                              <span className="text-yellow-400">★</span>
                              <span className="text-yellow-400">★</span>
                              <span className="text-yellow-400">★</span>
                              <span className="text-yellow-400">★</span>
                          </div>
                      </div>
                  </div>
                </div>
            </div>
    );
};

export default Services