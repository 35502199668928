import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Check, X, Loader2 } from 'lucide-react';
import { getAuth } from 'firebase/auth';
import { useTranslation } from 'react-i18next';

function CheckoutSuccessPage() {
  const [updateStatus, setUpdateStatus] = useState('loading');
  const navigate = useNavigate();
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const { t } = useTranslation();

  useEffect(() => {
    const verifyAndUpdateSubscription = async () => {
      try {
        const searchParams = new URLSearchParams(window.location.search);
        let userId = searchParams.get('userId');

        if (!userId) {
          const auth = getAuth();
          const currentUser = auth.currentUser;

          if (!currentUser) {
            throw new Error('No user ID provided and no current user');
          }

          userId = currentUser.uid;
        }

        // Call server to update subscription status
        const response = await fetch(`${BASE_URL}/update-subscription-status`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ userId })
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.error || 'Failed to update subscription');
        }

        setUpdateStatus('success');
      } catch (error) {
        setUpdateStatus('error');
      }
    };

    verifyAndUpdateSubscription();
  }, [BASE_URL]);

  // Redirect based on status
  useEffect(() => {
    let redirectTimer;
    if (updateStatus === 'success') {
        redirectTimer = setTimeout(() => {
        navigate('/login');
      }, 3000);
    } else if (updateStatus === 'error') {
        redirectTimer = setTimeout(() => {
        navigate('/signup');
      }, 3000);
    }

    return () => {
      if (redirectTimer) clearTimeout(redirectTimer);
    };
  }, [updateStatus, navigate]);

  const renderContent = () => {
    switch (updateStatus) {
      case 'loading':
        return (
          <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-br from-gray-100 to-gray-200 p-4">
            <div className="bg-white rounded-2xl shadow-xl p-8 text-center max-w-md w-full">
              <Loader2 className="mx-auto mb-4 h-16 w-16 text-blue-500 animate-spin" />
              <h2 className="text-2xl font-bold text-gray-800 mb-2">{t('processingSub')}</h2>
              <p className="text-gray-600">{t('pleaseWait')}</p>
            </div>
          </div>
        );
      
      case 'success':
        return (
          <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-br from-green-50 to-green-100 p-4">
            <div className="bg-white rounded-2xl shadow-xl p-8 text-center max-w-md w-full">
              <div className="bg-green-500 text-white rounded-full p-4 inline-block mb-4">
                <Check className="h-12 w-12" />
              </div>
              <h2 className="text-3xl font-bold text-green-800 mb-2">{t('subSuccess')}</h2>
              <p className="text-green-600 mb-4">{t('proAccountActive')}</p>
              <p className="text-gray-500 text-sm">{t('redirecting')}</p>
            </div>
          </div>
        );
      
      case 'error':
        return (
          <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-br from-red-50 to-red-100 p-4">
            <div className="bg-white rounded-2xl shadow-xl p-8 text-center max-w-md w-full">
              <div className="bg-red-500 text-white rounded-full p-4 inline-block mb-4">
                <X className="h-12 w-12" />
              </div>
              <h2 className="text-3xl font-bold text-red-800 mb-2">{t('subFailed')}</h2>
              <p className="text-red-600 mb-4">{t('weCoudntProcess')}</p>
              <p className="text-gray-500 text-sm">{t('redirectingSignUp')}</p>
            </div>
          </div>
        );
      
      default:
        return null;
    }
  };

  return renderContent();
}

export default CheckoutSuccessPage;