import React, { useState, useEffect } from 'react';

const HealthScoreSection = ({ score }) => {
  const [safeScore, setSafeScore] = useState(null);

  useEffect(() => {
    // Only set the score if it exists and has a valid score value
    if (score && score.score) {
      setSafeScore(score);
    }
  }, [score]);

  // Don't render anything if the score is not yet ready
  if (!safeScore) {
    return null;
  }

  // Determine trend color and styling
  const getTrendStyles = () => {
    switch (score.trend) {
      case '↑':
        return 'text-green-600 bg-green-50';
      case '↓':
        return 'text-red-600 bg-red-50';
      default:
        return 'text-yellow-600 bg-yellow-50';
    }
  };

  return (
    <div className="bg-gradient-to-r from-red-50 to-white p-6 rounded-xl mb-6 shadow-sm">
      <div className="flex flex-col space-y-4">
        {/* Score and Date Container */}
        <div className="flex flex-col px-12 py-6 sm:flex-row justify-between items-center text-center sm:text-left">
          {/* Health Score and Date Container */}
          <div className="flex flex-col sm:flex-row items-center sm:items-start space-y-2 sm:space-y-0 sm:space-x-4 w-full">
            <div className="flex flex-col items-center sm:items-start">
              <h2 className="text-sm font-medium text-gray-600 mb-1">Health Score</h2>
              <span className="text-5xl font-bold text-[#ce3d3d]">{score.score}</span>
              
              {/* Analysis Date - Placed below score on all screen sizes */}
              <div className="flex flex-col items-center sm:items-start mt-2">
                <span className="text-xs text-gray-500">Analysis Date</span>
                <span className="text-sm font-medium text-gray-700">{score.date}</span>
              </div>
            </div>
          </div>

          {/* Trend Indicator */}
          <div className={`
            flex items-center space-x-2 
            ${getTrendStyles()} 
            px-3 py-1 rounded-full 
            mt-3 sm:mt-0
          `}>
            <span className="text-2xl font-bold">{score.trend}</span>
            <span className="text-sm font-medium">{score.trendText}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HealthScoreSection;