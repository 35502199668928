import React from 'react';
import { ChevronRight, FileText } from 'lucide-react';
import CaseStudyImage from '../../Assets/CaseStudyPreview.png';
import { useTranslation } from 'react-i18next';

const CaseStudyPreview = () => {

    const { t } = useTranslation();

    // URL to your hosted PDF - replace with actual URL
    const pdfUrl = "https://drive.google.com/file/d/1_qWPGod-NRgFQPFUpwIo4f3Wj494uIJR/view?usp=sharing";

    return (
        <div className="w-full bg-gradient-to-b from-white via-gray-50 to-white py-24">
        <div className="max-w-[1240px] mx-auto px-6">
            {/* Header */}
            <h2 className="text-3xl sm:text-4xl font-bold text-center mb-16">
            <span className="bg-gradient-to-r from-red-600 to-red-500 bg-clip-text text-transparent">
                {t('LatestCaseStudy')}
            </span>
            </h2>

            {/* Case Study Card */}
            <div className="max-w-2xl mx-auto">
            <a 
                href={pdfUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="block"
            >
                <div className="group relative bg-white rounded-2xl shadow-xl overflow-hidden hover:shadow-2xl transition-all duration-300 cursor-pointer">
                {/* Preview Image */}
                <div className="relative aspect-[1.414/1] bg-gray-100 overflow-hidden">
                    {/* PDF Preview - replace src with actual preview image */}
                    <img
                    src={CaseStudyImage}
                    alt="Case Study Preview"
                    className="w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-300"
                    />
                    
                    {/* Overlay */}
                    <div className="absolute inset-0 bg-gradient-to-t from-black/60 via-black/20 to-transparent">
                    <div className="absolute bottom-6 left-6 right-6 text-white">
                        <h3 className="text-2xl font-bold mb-2">
                        Revolutionizing Blood Test Analysis
                        </h3>
                        <p className="text-white/90 mb-4">
                        How BloodFlow is Disrupting a 50-Year-Old Workflow
                        </p>
                        <div className="flex items-center text-sm text-white/75">
                        <FileText className="size-4 mr-2" />
                        <span>5 pages • 01/2025</span>
                        </div>
                    </div>
                    </div>
                </div>

                {/* Card Footer */}
                <div className="p-6 bg-gradient-to-br from-red-600 to-red-500">
                    <div className="flex items-center justify-between text-white">
                    <span className="font-semibold">{t('readFull')}</span>
                    <ChevronRight className="size-5 transform group-hover:translate-x-1 transition-transform" />
                    </div>
                </div>
                </div>
            </a>

            {/* Description */}
            <p className="mt-6 text-gray-600 text-center">
                {t('learnHow')}
            </p>
            </div>
        </div>
        </div>
    );
};

export default CaseStudyPreview;