import React, { useState, useEffect } from 'react';
import { createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import { auth } from "../../firebase";
import { doc, setDoc, Timestamp } from "firebase/firestore"; 
import { db } from "../../firebase";
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';import { Link as RouterLink } from 'react-router-dom';
import { ChevronRight } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import BloodFlowLogo from "../../Assets/logoBloodFlowNav.png";
import { useLocation } from 'react-router-dom';

function SignUpPage() {
  const [username, setUsername] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [userEmail, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [medicalCondition, setMedicalCondition] = useState("");
  const [loading, setLoading] = useState(false);
  const [planData, setPlanData] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const { t } = useTranslation();

  useEffect(() => {
      // Get planData from navigation state
      if (location.state?.planData) {
          setPlanData(location.state.planData);
      }
  }, [location]);

  // Check checkout status on mount
  useEffect(() => {
    const checkCheckoutStatus = async () => {
      const checkoutSuccess = sessionStorage.getItem('checkoutSuccess');
      const storedUserData = sessionStorage.getItem('signupUserData');

      if (checkoutSuccess && storedUserData) {
        try {
          const userData = JSON.parse(storedUserData);
          
          const userCredential = await createUserWithEmailAndPassword(
            auth, 
            userData.email, 
            userData.password
          );
          
          const user = userCredential.user;
          await sendEmailVerification(user);

          // Fetch checkout details from server
          const response = await fetch(`${BASE_URL}/get-checkout-details`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ email: userData.email })
          });

          const checkoutDetails = await response.json();

          // Create user document in Firestore
          const userRef = doc(db, "users", user.uid);
          await setDoc(userRef, {
            username: userData.username,
            dateOfBirth: Timestamp.fromDate(userData.dateOfBirth),
            medicalCondition: userData.medicalCondition,
            subscriptionStatus: 'pro',
            stripeCustomerId: checkoutDetails.customerId,
            subscriptionId: checkoutDetails.subscriptionId,
            currentPeriodEnd: checkoutDetails.currentPeriodEnd,
            createdAt: Timestamp.now()
          });

          // Clean up session storage
          sessionStorage.removeItem('checkoutSuccess');
          sessionStorage.removeItem('signupUserData');

          toast.success('Signup Successful');
          navigate("/login");
        } catch (error) {
          console.error('Pro user creation error:', error);
          toast.error('Signup Failed');
        }
      }
    };

    checkCheckoutStatus();
  }, [navigate, BASE_URL]);

  const registerUser = async (e) => {
    e.preventDefault();
    setLoading(true);
  
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(userEmail)) {
      setLoading(false);
      return toast.error(t('registerToastErrEmail'));
    }
  
    try {
      // If there's a plan selected, handle pro plan signup
      if (planData) {
        // First, create the user
        const userCredential = await createUserWithEmailAndPassword(auth, userEmail, password);
        const user = userCredential.user;
        
        // Send email verification
        await sendEmailVerification(user);
  
        // Create initial user document
        const userRef = doc(db, "users", user.uid);
        await setDoc(userRef, {
          email: userEmail,
          username: username,
          dateOfBirth: Timestamp.fromDate(dateOfBirth),
          medicalCondition: medicalCondition,
          subscriptionStatus: 'pending',
          analysisCount: 0,
          stripeCustomerId: null,
          subscriptionId: null,
          currentPeriodEnd: null,
          createdAt: Timestamp.now()
        });
  
        // Get user ID token for API calls
        const idToken = await user.getIdToken();
  
        // Initialize Stripe
        const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
        
        // Create checkout session
        const response = await fetch(`${BASE_URL}/subscription/create-checkout`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${idToken}`
          },
          body: JSON.stringify({
            priceId: planData.priceId,
            email: userEmail,
            userId: user.uid
          })
        });
  
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.error || 'Failed to create checkout session');
        }
  
        const { sessionId } = await response.json();
  
        // Redirect to Stripe Checkout
        const result = await stripe.redirectToCheckout({ sessionId });
  
        if (result.error) {
          throw new Error(result.error.message);
        } else {
          sendEmail(userEmail, username);
        }
      } else {
        // Free user signup flow
        const userCredential = await createUserWithEmailAndPassword(auth, userEmail, password);
        const user = userCredential.user;
        
        await sendEmailVerification(user);
  
        const userRef = doc(db, "users", user.uid);
        await setDoc(userRef, {
          username: username,
          dateOfBirth: Timestamp.fromDate(dateOfBirth),
          medicalCondition: medicalCondition,
          subscriptionStatus: 'free',
          analysisCount: 0,
          stripeCustomerId: null,
          subscriptionId: null,
          currentPeriodEnd: null,
          createdAt: Timestamp.now()
        });
  
        // Add to marketing emails list
        await fetch(`${BASE_URL}/add-email-to-database`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ userEmail: userEmail }),
        });
  
        toast.success(t('registerToastSuc'));
        sendEmail(userEmail, username);
        toast.info(t('registerToastVerifyEmail'));
        navigate("/login");
      }
    } catch (error) {
      setLoading(false);
      console.error('Signup error:', error);
      
      if (error.code === 'auth/email-already-in-use') {
        toast.error(t('registerToastErrDupl'));
      } else {
        toast.error(t('registerToastErr'));
      }
    }
  };

  const backToHome = () => {
    navigate("/");
  };

  const sendEmail = async (email, name) => {
      const response = await fetch(`${BASE_URL}/send-email-welcome`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({ userEmail: email, userName: name }),
      });

      if (response.ok) {
          console.log("Email sent");
      } else {
          console.error('Failed to send email');
      }
  }
  
    return (
        <div className="min-h-screen bg-gradient-to-br from-red-50 to-white flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
          <div className="max-w-md w-full space-y-8 bg-white rounded-2xl shadow-xl p-8">
            <div>
              <img
                onClick={backToHome}
                className="mx-auto h-16 w-auto cursor-pointer hover:opacity-90 transition-opacity"
                src={BloodFlowLogo}
                alt="BloodFlow Logo"
              />
              <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                {t('registeConta')}
              </h2>
            </div>
    
            <form onSubmit={registerUser} className="mt-8 space-y-6">
              <div className="space-y-4">
                <div>
                  <input
                    type="text"
                    placeholder={t('nome')}
                    value={username}
                    required
                    onChange={(e) => setUsername(e.target.value)}
                    className="appearance-none relative block w-full px-4 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-xl focus:outline-none focus:ring-2 focus:ring-red-500 focus:border-transparent transition-all duration-200"
                  />
                </div>
    
                <div className="relative">
                  <DatePicker
                    className="custom-date-picker appearance-none relative block w-full px-4 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-xl focus:outline-none focus:ring-2 focus:ring-red-500 focus:border-transparent transition-all duration-200"
                    onChange={setDateOfBirth}
                    value={dateOfBirth}
                    format="dd/MM/yyyy"
                    clearIcon={null}
                    calendarIcon={null}
                    dayPlaceholder="DD"
                    monthPlaceholder="MM"
                    yearPlaceholder="YYYY"
                    required
                  />
                  <style jsx global>{`
                    .custom-date-picker .react-date-picker__wrapper {
                      border: none !important;
                      width: 100%;
                      background: transparent;
                    }
                    .custom-date-picker .react-date-picker__inputGroup__input {
                      border: none !important;
                      outline: none !important;
                      color: #111827;
                    }
                    .custom-date-picker .react-date-picker__inputGroup__divider {
                      color: #6B7280 !important;
                    }
                    .custom-date-picker .react-date-picker__inputGroup__input:focus {
                      outline: none !important;
                    }
                    .custom-date-picker .react-date-picker__calendar {
                      background: white;
                      border: 1px solid #E5E7EB !important;
                      border-radius: 1rem !important;
                      box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
                    }
                    .custom-date-picker .react-calendar {
                      border-radius: 1rem;
                      border: none;
                      padding: 1rem;
                    }
                    .custom-date-picker .react-calendar__tile--active {
                      background: #EF4444 !important;
                      border-radius: 0.5rem;
                    }
                    .custom-date-picker .react-calendar__tile--now {
                      background: #FEE2E2;
                      border-radius: 0.5rem;
                    }
                  `}</style>
                </div>
    
                <div>
                  <input
                    type="text"
                    placeholder="Email"
                    value={userEmail}
                    required
                    onChange={(e) => setEmail(e.target.value)}
                    className="appearance-none relative block w-full px-4 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-xl focus:outline-none focus:ring-2 focus:ring-red-500 focus:border-transparent transition-all duration-200"
                  />
                </div>
    
                <div>
                  <input
                    type="password"
                    placeholder="Password"
                    value={password}
                    required
                    onChange={(e) => setPassword(e.target.value)}
                    className="appearance-none relative block w-full px-4 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-xl focus:outline-none focus:ring-2 focus:ring-red-500 focus:border-transparent transition-all duration-200"
                  />
                </div>
    
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    {t('medCondition')}
                  </label>
                  <input
                    type="text"
                    placeholder={t('medConditionPH')}
                    value={medicalCondition}
                    required
                    onChange={(e) => setMedicalCondition(e.target.value)}
                    className="appearance-none relative block w-full px-4 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-xl focus:outline-none focus:ring-2 focus:ring-red-500 focus:border-transparent transition-all duration-200"
                  />
                </div>
              </div>
    
              <button
                type="submit"
                className="group relative w-full flex justify-center py-3 px-4 border border-transparent text-base font-medium rounded-xl text-white shadow-lg transition-all duration-300 hover:shadow-xl hover:-translate-y-0.5"
                disabled={loading}
              >
                <div className="absolute inset-0 bg-gradient-to-r from-red-500 to-red-600 rounded-xl transition-transform duration-300 group-hover:scale-105" />
                <span className="relative z-10 flex items-center justify-center">
                  {loading ? (
                    <div className="w-5 h-5 border-2 border-white/30 border-t-white rounded-full animate-spin" />
                  ) : (
                    <>
                      {t('registerAccount')}
                      <ChevronRight className="ml-2 size-4 transition-transform group-hover:translate-x-1" />
                    </>
                  )}
                </span>
              </button>
              </form>

              <div className="text-center space-y-4">
                <p className="text-sm font-medium text-gray-900">
                  {t('jaTemConta')}
                </p>
                {/* Login Button */}
                <RouterLink
                  to="/login"
                  className="group relative w-full inline-flex justify-center py-3 px-4 border border-transparent text-base font-medium rounded-xl text-white shadow-lg transition-all duration-300 hover:shadow-xl hover:-translate-y-0.5"
                >
                  <div className="absolute inset-0 bg-gradient-to-r from-gray-800 to-gray-900 rounded-xl transition-transform duration-300 group-hover:scale-105" />
                  <span className="relative z-10 flex items-center justify-center">
                    {t('login')}
                    <ChevronRight className="ml-2 size-4 transition-transform group-hover:translate-x-1" />
                  </span>
                </RouterLink>
              </div>
          </div>
        </div>
    );
}

export default SignUpPage;