import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import AnimatedGradientText from '../magicui/shinyTextButton';
import { useTranslation } from 'react-i18next';
import Particles from '../magicui/particles';
import { ChevronRight } from "lucide-react";
import GradualSpacing from '../magicui/gradual-spacing';

const Hero = () => {
  const { t } = useTranslation();

  const [topButtonVisible, setTopButtonVisible] = useState(false);
  const [bottomButtonVisible, setBottomButtonVisible] = useState(false);
  const [subtitleVisible, setSubtitleVisible] = useState(false);

  useEffect(() => {
    const topTimer = setTimeout(() => {
        setTopButtonVisible(true);
    }, 800);

    const bottomTimer = setTimeout(() => {
        setBottomButtonVisible(true);
        setSubtitleVisible(true);
    }, 1400);

    return () => {
        clearTimeout(topTimer);
        clearTimeout(bottomTimer);
    };
}, []);

return (
  <div id="Home" className="relative min-h-screen bg-gradient-to-b from-white via-red-50/30 to-white flex justify-center items-center overflow-hidden">
    {/* Particles background with reduced quantity for cleaner look */}
    <Particles
      className="absolute inset-0"
      quantity={140}
      ease={150}
      color={'ff0000'}
      size={0.6}
      refresh
    />

    {/* Background decorative elements */}
    <div className="absolute inset-0 overflow-hidden">
      <div className="absolute -top-1/2 -right-1/2 w-[1000px] h-[1000px] rounded-full bg-gradient-to-b from-red-100/20 to-transparent" />
      <div className="absolute -bottom-1/2 -left-1/2 w-[1000px] h-[1000px] rounded-full bg-gradient-to-t from-red-100/20 to-transparent" />
    </div>

    <div className="relative z-10 text-center -mt-40 max-w-[1000px] px-4">
      {/* Calendly Button */}
      <div className={`mb-10 transition-transform duration-500 ease-out ${topButtonVisible ? 'translate-y-0 opacity-100' : '-translate-y-8 opacity-0'}`}>
        <a
          href="https://calendly.com/tiago-costa-bloodflow"
          target="_blank"
          rel="noopener noreferrer"
          className="inline-flex items-center group rounded-full bg-white/80 backdrop-blur-sm shadow-lg hover:shadow-xl transition-all duration-300 hover:-translate-y-0.5"
        >
          <AnimatedGradientText>
            <span className="flex items-center">
              <span className="text-xl">📅</span>
              <span className="mx-2 h-3 w-[1px] bg-gray-300" />
              <span className="inline animate-gradient bg-gradient-to-r from-[#ffaa40] via-[#9c40ff] to-[#ffaa40] bg-[length:var(--bg-size)_100%] bg-clip-text text-transparent">
                {t('buttonHero')}
              </span>
              <ChevronRight className="ml-2 size-3 transition-transform duration-300 ease-in-out group-hover:translate-x-1" />
            </span>
          </AnimatedGradientText>
        </a>
      </div>

      {/* Main Title */}
      <div className='mb-8 sm:mb-12'>
        <GradualSpacing 
          className="text-4xl sm:text-5xl md:text-7xl font-bold text-transparent bg-gradient-to-b from-gray-900 to-gray-600 bg-clip-text leading-tight"
          text={t('title3')} 
        />
      </div>

      {/* Subtitle */}
      <div className={`relative mx-auto max-w-[600px] px-4 transition-transform duration-500 ease-out ${subtitleVisible ? 'translate-y-0 opacity-100' : 'translate-y-12 opacity-0'}`}>
        <p className="text-base sm:text-lg md:text-xl font-medium text-transparent bg-gradient-to-b from-gray-800 to-gray-600 bg-clip-text leading-relaxed">
          {t('subtitle2')}
        </p>
      </div>

      {/* CTA Button */}
      <div className={`mt-12 transition-transform duration-500 ease-out ${bottomButtonVisible ? 'translate-y-0 opacity-100' : 'translate-y-12 opacity-0'}`}>
        <RouterLink to="/signup">
          <button className='group relative overflow-hidden bg-red-600 w-[350px] rounded-xl font-semibold py-4 text-white shadow-lg transition-all duration-300 hover:shadow-xl hover:-translate-y-0.5'>
            <div className="absolute inset-0 bg-gradient-to-r from-red-500 to-red-600 transition-transform duration-300 group-hover:scale-105" />
            <span className="relative z-10 flex items-center justify-center">
              {t('buttonHero2')}
              <ChevronRight className="ml-2 size-4 transition-transform duration-300 group-hover:translate-x-1" />
            </span>
          </button>
        </RouterLink>
      </div>
    </div>
  </div>
);
};

export default Hero;
