import React from "react";
import { useTranslation } from "react-i18next";

const DeletePopup = ({ show, onClose, onConfirm }) => {
  const { t } = useTranslation();

  if (!show) return null;

  return (
    <div className="fixed inset-0 z-50">
      {/* Backdrop */}
      <div className="fixed inset-0 bg-black/30 backdrop-blur-sm transition-opacity" onClick={onClose} />

      {/* Modal */}
      <div className="fixed inset-0 z-50 flex items-center justify-center p-4 sm:p-0">
        <div className="relative transform overflow-hidden rounded-xl bg-white shadow-xl transition-all w-full max-w-md">
          {/* Modal Content */}
          <div className="p-6">
            {/* Modal Header */}
            <div className="flex items-center space-x-4">
              <div className="flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100">
                <svg 
                  className="h-6 w-6 text-red-600" 
                  fill="none" 
                  viewBox="0 0 24 24" 
                  strokeWidth="1.5" 
                  stroke="currentColor"
                >
                  <path 
                    strokeLinecap="round" 
                    strokeLinejoin="round" 
                    d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" 
                  />
                </svg>
              </div>
              <div className="flex-1">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  {t('atencao')}
                </h3>
                <p className="mt-2 text-sm text-gray-500">
                  {t('loseData')}
                </p>
              </div>
            </div>

            {/* Modal Actions */}
            <div className="mt-6 flex flex-col-reverse gap-2 sm:flex-row sm:justify-end">
              <button
                type="button"
                onClick={onClose}
                className="inline-flex w-full justify-center rounded-xl bg-white px-4 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:w-auto transition-all duration-300"
              >
                {t('cancelar')}
              </button>
              <button
                type="button"
                onClick={onConfirm}
                className="inline-flex w-full justify-center rounded-xl bg-gradient-to-r from-[#d73838] to-[#a31d1d] px-4 py-2.5 text-sm font-semibold text-white shadow-sm hover:from-[#a31d1d] hover:to-[#d73838] sm:w-auto transition-all duration-300"
              >
                {t('apagar')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeletePopup;