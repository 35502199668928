import React, { useState, useEffect } from "react";
import { db } from "../../firebase";
import { doc, setDoc } from "firebase/firestore";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const EditPersonalInfo = ({ show, onClose, userInfo, userId }) => {
  const [formData, setFormData] = useState(userInfo);
  const { t } = useTranslation();

  useEffect(() => {
    if (userInfo) {
      setFormData(userInfo);
    }
  }, [userInfo]);

  if (!show) return null;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      const userDoc = doc(db, "users", userId);
      await setDoc(userDoc, {
        username: formData.username,
        dateOfBirth: userInfo.dateOfBirth,
        medicalCondition: formData.medicalCondition,
      });

      toast.success(t('dadosAtualizadosPopup'));
      onClose();
      window.location.reload();
    } catch (error) {
      toast.error(t('dadosAtualizadosPopupError'));
    }
  };

  return (
    <div className="fixed inset-0 z-50">
      {/* Backdrop */}
      <div className="fixed inset-0 bg-black/30 backdrop-blur-sm transition-opacity" onClick={onClose} />

      {/* Modal */}
      <div className="fixed inset-0 z-50 flex items-center justify-center p-4 sm:p-0">
        <div className="relative transform overflow-hidden rounded-xl bg-white shadow-xl transition-all w-full max-w-lg">
          <div className="p-6">
            {/* Header */}
            <div className="flex items-center space-x-4 mb-6">
              <div className="flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-[#fde8e8]">
                <svg 
                  className="h-6 w-6 text-[#ce3d3d]" 
                  fill="none" 
                  viewBox="0 0 24 24" 
                  strokeWidth="1.5" 
                  stroke="currentColor"
                >
                  <path 
                    strokeLinecap="round" 
                    strokeLinejoin="round" 
                    d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" 
                  />
                </svg>
              </div>
              <h2 className="text-xl font-semibold text-gray-900">{t('editarInfo')}</h2>
            </div>

            {/* Form */}
            <form onSubmit={handleSave} className="space-y-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">{t('nome')}</label>
                <input
                  type="text"
                  name="username"
                  value={formData.username}
                  onChange={handleChange}
                  className="w-full px-4 py-2.5 rounded-xl border border-gray-300 focus:ring-2 focus:ring-[#ce3d3d] focus:border-transparent transition-all duration-300 outline-none"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">{t('condicaoMedica')}</label>
                <input
                  type="text"
                  name="medicalCondition"
                  value={formData.medicalCondition}
                  onChange={handleChange}
                  className="w-full px-4 py-2.5 rounded-xl border border-gray-300 focus:ring-2 focus:ring-[#ce3d3d] focus:border-transparent transition-all duration-300 outline-none"
                />
              </div>

              {/* Actions */}
              <div className="flex flex-col-reverse gap-2 sm:flex-row sm:justify-end pt-4">
                <button
                  type="button"
                  onClick={onClose}
                  className="inline-flex w-full justify-center rounded-xl bg-white px-4 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:w-auto transition-all duration-300"
                >
                  {t('cancelar')}
                </button>
                <button
                  type="submit"
                  className="inline-flex w-full justify-center rounded-xl bg-gradient-to-r from-[#d73838] to-[#a31d1d] px-4 py-2.5 text-sm font-semibold text-white shadow-sm hover:from-[#a31d1d] hover:to-[#d73838] sm:w-auto transition-all duration-300"
                >
                  {t('save')}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditPersonalInfo;