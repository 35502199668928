import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Cell,
  ReferenceLine
} from 'recharts';

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-white p-3 border rounded-lg shadow-lg">
        <p className="text-sm font-semibold mb-1">{label}</p>
        <p className="text-sm text-[#ce3d3d]">
          Score: <span className="font-bold">{payload[0].value.toFixed(1)}</span>
        </p>
      </div>
    );
  }
  return null;
};

const MyBarChart = ({ data, selectedFileMetadata, onBarClick }) => {
  const formattedData = data.map(item => ({
    ...item,
    time: new Date(item.time).toLocaleDateString()
  }));

  return (
    <ResponsiveContainer width="100%" height={450}>
      <BarChart
        data={formattedData}
        margin={{ top: 20, right: 30, left: 20, bottom: 25 }}
        barSize={40}
      >
        <CartesianGrid
          strokeDasharray="3 3"
          vertical={false}
          stroke="#f0f0f0"
        />
        <XAxis
          dataKey={(item) => `${item.time} - ${item.name}`}
          tickFormatter={(value) => value.split(" - ")[0]}
          stroke="#666"
          fontSize={12}
          tickMargin={10}
          axisLine={{ stroke: '#e0e0e0' }}
        />
        <YAxis
          domain={[0, 10]}
          stroke="#666"
          fontSize={12}
          tickMargin={10}
          axisLine={{ stroke: '#e0e0e0' }}
          ticks={[0, 2, 4, 6, 8, 10]}
        />
        <Tooltip
          content={<CustomTooltip />}
          cursor={{ fill: 'rgba(0, 0, 0, 0.05)' }}
        />
        <Legend
          wrapperStyle={{
            paddingTop: '20px',
            fontSize: '14px'
          }}
        />
        <ReferenceLine
          y={5}
          stroke="#666"
          strokeDasharray="3 3"
          strokeOpacity={0.5}
        />
        <Bar
          dataKey="score"
          name={<span style={{ color: '#000000' }}>Health Score</span>}
          radius={[4, 4, 0, 0]}
          legendType="square"
          fill="#ce3d3d"
        >
          {data.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={selectedFileMetadata && entry.name === selectedFileMetadata.name 
                ? 'url(#selectedGradient)' 
                : 'url(#normalGradient)'}
              onClick={() => onBarClick(entry.name)}
              style={{ cursor: 'pointer' }}
            />
          ))}
        </Bar>
        <defs>
          <linearGradient id="normalGradient" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="#ff6b6b" />
            <stop offset="100%" stopColor="#ce3d3d" />
          </linearGradient>
          <linearGradient id="selectedGradient" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="#4299e1" />
            <stop offset="100%" stopColor="#2b6cb0" />
            <animate
              attributeName="y1"
              values="0;0.5;0"
              dur="2s"
              repeatCount="indefinite"
            />
          </linearGradient>
        </defs>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default MyBarChart;