import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import {auth, storage, db} from "../../firebase";
import { doc, getDoc } from "firebase/firestore";
import { ref, listAll, getMetadata, uploadBytes, getDownloadURL, updateMetadata, deleteObject } from "firebase/storage";
import BloodFlowLogo from "../../Assets/BloodflowLogo.png";
import BloodFlowLogoNL from "../../Assets/BloodflowLogoNoLetters.png";
import PDF_ICON from "../../Assets/pdf-icon.png";
import { toast } from 'react-toastify';
import { AiOutlineMenu, AiOutlineClose } from 'react-icons/ai';
import MyBarChart from './BarGraph';
import CircularChart from './RadialGraph';
import axios from "axios"; 
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import DeletePopup from './DeletePopup';
import NewFilePopup from './NewFilePopup';
import EditPersonalInfo from './EditPersonalInfo';
import Spinner from '../Spinner';
import { useTranslation } from 'react-i18next';
import BiomarkerChart from './BiomarkerChart';
import AnalysisSection, { parseAnalysisText } from './AnalysisSection';
import HealthScoreSection from './HealthScoreSection';
import { FileX2, Upload, PieChart, Trophy, TrendingUp,
     Activity, LineChart, Target, Heart,
     Brain, Search, FileText, ChartBar, Inbox } from 'lucide-react';
import UpgradeModal from './UpgradeModal';
//import SpatialVisualization from './SpatialVisualization';

function Dashboard() {

    const [user, setUser] = useState(null);
    const [userData, setUserData] = useState(null);
    const [userAge, setUserAge] = useState(null);
    const [nav, setNav] = useState(false);
    const navigate = useNavigate();
    const [resultsVisible, setResultsVisible] = useState(true);
    const [userFiles, setUserFiles] = useState([]);
    const [selectedFileData, setSelectedFileData] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [fileScores, setFileScores] = useState([]);
    const [loading, setLoading] = useState(false);
    const [scores, setScores] = useState([]);
    const [analysisPerformed, setAnalysisPerformed] = useState(false);
    const [displayedText, setDisplayedText] = useState("");
    //const [screeningIndex, setScreeningIndex] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [showNewFileModal, setShowNewFileModal] = useState(false);
    const [showEditPopup, setShowEditPopup] = useState(false);
    const [loadingWindow, setLoadingWindow] = useState(true);
    const analysisContainerRef = useRef(null);
    const [smartReport, setSmartReport] = useState(null);
    const [smartReportLoading, setSmartReportLoading] = useState(false);
    const [chartBiomarkerLoading, setChartBiomarkerLoading] = useState(false);
    //const [analysisScreeningLoading, setAnalysisScreeningLoading] = useState(false);
    //const [screeningAnalysis, setScreeningAnalysis] = useState("");
    //const [screeningPerformed, setScreeningPerformed] = useState(false);
    //const [screeningResult, setScreeningResult] = useState("");
    const [activeTab, setActiveTab] = useState("fullAnalysis");
    const [biomarkerData, setBiomarkerData] = useState([]);
    const [biomarkerRanges, setBiomarkerRanges] = useState({});
    const [openSections, setOpenSections] = useState({});
    const [showUpgradeModal, setShowUpgradeModal] = useState(false);

    const { t } = useTranslation();

    const BASE_URL = process.env.REACT_APP_BASE_URL;

    useEffect(() => {
        const timer = setTimeout(() => {
          setLoadingWindow(false);
        }, 3000);
    
        return () => clearTimeout(timer);
      }, []);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async (user) => {
            if (user) {
                setUser(user);
                const userData = await fetchUserData(user);
                if (userData.dateOfBirth) {
                    const dateOfBirth = new Date(userData.dateOfBirth.seconds * 1000);
                    const today = new Date();
                    const age = today.getFullYear() - dateOfBirth.getFullYear();
                    setUserAge(age);
                }
            } else {
                setUser(null);
                navigate('/login');
            }
        });
        return () => unsubscribe();
    }, [navigate, userAge]);
    
    useEffect(() => {
        if (user) {
            fetchUserData(user);
        }
    }, [user]);
    
    const fetchUserData = async (user) => {
        try {
            const userId = user.uid;
            const userDocRef = doc(db, "users", userId);
            const userDocSnapshot = await getDoc(userDocRef);
            if (userDocSnapshot.exists()) {
                const userData = userDocSnapshot.data();
                setUserData(userData);
                return userData;
            } else {
                return null;
            }
        } catch (error) {
            return null;
        }
    };

    useEffect(() => {
        const fetchUserFiles = async () => {
            try {
                if (!user || !userData) return;
    
                const userId = auth.currentUser.uid;
                const userFilesRef = ref(storage, `bloodTestResults/${userId}`);
                const userFilesList = await listAll(userFilesRef);
    
                const filesWithMetadata = await Promise.all(userFilesList.items.map(async (item) => {
                    const metadata = await getMetadata(item);
                    return { name: item.name, time: metadata.timeCreated };
                }));
    
                const sortedFiles = filesWithMetadata.sort((a, b) => new Date(a.time) - new Date(b.time));
    
                setUserFiles(sortedFiles.map(file => file.name));
    
                if (sortedFiles.length === 0) {

                } else {
                    const scores = await Promise.all(sortedFiles.map(async (file) => {
                        const fileRef = ref(storage, `bloodTestResults/${userId}/${file.name}`);
                        const metadata = await getMetadata(fileRef);
                        return { time: metadata.timeCreated, score: parseFloat(metadata.customMetadata?.score || '0'), name: metadata.name };
                    }));
    
                    setFileScores(scores.filter(score => score !== null));
    
                    const latestFile = sortedFiles[sortedFiles.length - 1].name;
                    if (latestFile) {
                        handleFileClick(latestFile);
                    }
                }

                await fetchAllSmartReports(userId);
    
            } catch (error) {
                toast.error(`${t('ficheirosErroToast')} ${userData.username}!`);
            }
        };
    
        if (user) {
            fetchUserFiles();
        }
        // eslint-disable-next-line
    }, [user, userData]);

    const fetchUpdatedScores = async () => {
        try {
            const userId = auth.currentUser.uid;
            const userFilesRef = ref(storage, `bloodTestResults/${userId}`);
            const userFilesList = await listAll(userFilesRef);

            const filesWithMetadata = await Promise.all(userFilesList.items.map(async (item) => {
                const metadata = await getMetadata(item);
                return { name: item.name, time: metadata.timeCreated };
            }));

            const sortedFiles = filesWithMetadata.sort((a, b) => new Date(a.time) - new Date(b.time));

            setUserFiles(sortedFiles.map(file => file.name));

            if (sortedFiles.length === 0) {

            } else {
                const scores = await Promise.all(sortedFiles.map(async (file) => {
                    const fileRef = ref(storage, `bloodTestResults/${userId}/${file.name}`);
                    const metadata = await getMetadata(fileRef);
                    return { time: metadata.timeCreated, score: parseFloat(metadata.customMetadata?.score || '0'), name: metadata.name };
                }));

                setFileScores(scores.filter(score => score !== null));
            }

        } catch (error) {
            toast.error(t('erroResultadosToast'));
        }
    };

    const handleFileClick = useCallback (async (fileName) => {
        try {
            const userId = auth.currentUser.uid;
            const fileRef = ref(storage, `bloodTestResults/${userId}/${fileName}`);
    
            const metadata = await getMetadata(fileRef);
            setSelectedFileData(metadata);
    
            if (metadata.customMetadata?.analysis !== undefined) {
                setDisplayedText(metadata.customMetadata.analysis);
                setAnalysisPerformed(true);
            } else {
                setAnalysisPerformed(false);
            }

            /*
            if (metadata.customMetadata?.screening !== undefined) {
                setScreeningAnalysis(metadata.customMetadata.screening);
                setScreeningPerformed(true);
            } else {
                setScreeningPerformed(false);
                setScreeningAnalysis("");
            }
            */
    
            try {
                const smartReport = await fetchSmartReport(userId, fileName);
                setSmartReport(smartReport);
                await fetchAllSmartReports(userId);
            } catch (error) {
                console.warn("Failed to fetch Smart Report:", error);
                setSmartReport(null);
            }

        } catch (error) {
            console.error("Error handling file click:", error);
            toast.error(t('erroFicheiroSelecionado'));
        }
    }, [t]);

    const saveSmartReportToStorage = async (smartReport, userId, fileName) => {
        const smartReportRef = ref(storage, `smartReportsJson/${userId}/${fileName}_smartReport.json`);
        
        const jsonData = JSON.stringify(smartReport);
        const blob = new Blob([jsonData], { type: "application/json" });
      
        return uploadBytes(smartReportRef, blob);
    };

    const fetchSmartReport = async (userId, fileName) => {
        try {
            const smartReportRef = ref(storage, `smartReportsJson/${userId}/${fileName}_smartReport.json`);
    
            try {
                await getMetadata(smartReportRef);
            } catch (error) {
                if (error.code === 'storage/object-not-found') {
                    console.warn('Smart Report file does not exist.');
                    return;
                }
                throw error;
            }
    
            const downloadURL = await getDownloadURL(smartReportRef);
            const response = await fetch(downloadURL);
            const smartReport = await response.json();
    
            return smartReport;
        } catch (error) {
            console.log('Failed to fetch Smart Report:', error);
            return null;
        }
    };

    const fetchAllSmartReports = async (userId) => {
        try {
            const folderRef = ref(storage, `smartReportsJson/${userId}`);
            const filesList = await listAll(folderRef);
            
            const allData = {};
            const ranges = {};
    
            setChartBiomarkerLoading(true);
    
            const filesWithMetadata = await Promise.all(
                filesList.items.map(async (file) => {
                    if (!file.name.endsWith('_smartReport.json')) return null;
                    const metadata = await getMetadata(file);
                    return {
                        file,
                        metadata,
                        timeCreated: metadata.timeCreated
                    };
                })
            );
    
            const sortedFiles = filesWithMetadata
                .filter(item => item !== null)
                .sort((a, b) => new Date(a.timeCreated) - new Date(b.timeCreated));
    
            await Promise.all(sortedFiles.map(async ({ file }) => {
                const downloadURL = await getDownloadURL(file);
                const response = await fetch(downloadURL);
                const smartReport = await response.json();
                
                if (smartReport && Array.isArray(smartReport.parameters)) {
                    smartReport.parameters.forEach(param => {
                        if (!param.name || !param.value || !param.range) return;
    
                        if (!allData[param.name]) {
                            allData[param.name] = [];
                        }
                        
                        const valueNum = parseFloat(param.value.split(' ')[0]);
                        if (!isNaN(valueNum)) {
                            allData[param.name].push(valueNum);
                        }
                        
                        if (!ranges[param.name] && param.range) {
                            const rangeParts = param.range.split('-');
                            if (rangeParts.length === 2) {
                                const min = parseFloat(rangeParts[0]);
                                const max = parseFloat(rangeParts[1]);
                                if (!isNaN(min) && !isNaN(max) && min < max) {
                                    ranges[param.name] = {
                                        min,
                                        max,
                                        unit: param.value.split(' ').slice(1).join(' ')
                                    };
                                }
                            }
                        }
                    });
                }
            }));
    
            const transformedData = [];
            
            // Process each file's data with its date
            sortedFiles.forEach(({ metadata }, index) => {
                const dataPoint = {
                    index: index + 1,
                    date: new Date(metadata.timeCreated).toLocaleDateString(),
                };
                Object.keys(allData).forEach(biomarker => {
                    if (allData[biomarker][index] !== undefined) {
                        dataPoint[biomarker] = allData[biomarker][index];
                    }
                });
                transformedData.push(dataPoint);
            });
    
            if (Object.keys(ranges).length > 0 && Object.keys(allData).length > 0) {
                setBiomarkerData(transformedData);
                setBiomarkerRanges(ranges);
            }
    
            setChartBiomarkerLoading(false);
    
        } catch (error) {
            console.error('Failed to fetch smart reports:', error);
            setBiomarkerData([]);
            setBiomarkerRanges({});
            setChartBiomarkerLoading(false);
        }
    };

    const handleAnalyzeClick = async () => {
        setLoading(true);
        try {
            const userId = auth.currentUser.uid;
            const fileRef = ref(storage, `bloodTestResults/${userId}/${selectedFileData.name}`);

            try {
                const metadata = await getMetadata(fileRef);
                setSelectedFileData(metadata);

                if(metadata.customMetadata?.analysis !== undefined) {
                    setDisplayedText(metadata.customMetadata.analysis);
                    setLoading(true);
                    setTimeout(() => {
                        setAnalysisPerformed(true);
                        analysisContainerRef.current.scrollIntoView({ behavior: 'smooth' });
                    }, 800);
                    return;
                }

                /*if(metadata.customMetadata?.screening !== undefined) {
                    setScreeningAnalysis(metadata.customMetadata.screening);
                    setAnalysisScreeningLoading(true);
                    setTimeout(() => {
                        setScreeningPerformed(true);
                        analysisContainerRef.current.scrollIntoView({ behavior: 'smooth' });
                    }, 800);
                    return;
                }*/
            } catch (error) {
                console.error('Error fetching file metadata:', error);
            }
        } catch (error) {
            console.error('Error fetching file metadata:', error);
        } finally {
            setLoading(false);
            //setAnalysisScreeningLoading(false);
        }

        setLoading(true);
        //setAnalysisScreeningLoading(true);
        setTimeout(() => {
            setAnalysisPerformed(true);
            //setScreeningPerformed(true);
            analysisContainerRef.current.scrollIntoView({ behavior: 'smooth' });
        }, 1200);

        try {
            const userId = auth.currentUser.uid;
        
            const fileRef = ref(storage, `bloodTestResults/${userId}/${selectedFileData.name}`);
            const downloadURL = await getDownloadURL(fileRef);
            const text = await convertPDFToText(downloadURL);

            try {
                // Make a lightweight check request first
                const checkResponse = await fetch(`${BASE_URL}/check-analysis-count`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ 
                        userID: userId,
                    }),
                });

                console.log('Check response:', checkResponse);
    
                if (checkResponse.status === 403) {
                    setShowUpgradeModal(true);
                    setAnalysisPerformed(false);
                    setLoading(false);
                    return; // Stop here if no more analyses available
                }
            } catch (error) {
                console.error('Error checking analysis count:', error);
            }
        
            setLoading(true); 
            const fullAnalysisPromise = analyseBloodTest(text)
                .then((fullAnalysisResult) => {
                    setLoading(false);
                    setDisplayedText(fullAnalysisResult);
        
                    if (fullAnalysisResult) {
                        const score = extractScoreFromAnalysis(fullAnalysisResult);
                        setScores([...scores, { time: new Date().toLocaleDateString(), score }]);
        
                        getMetadata(fileRef).then((fileMetadata) => {
                            updateMetadata(fileRef, {
                                customMetadata: {
                                    ...fileMetadata.customMetadata,
                                    score: parseFloat(score),
                                    analysis: fullAnalysisResult,
                                },
                            }).catch((error) => console.error("Error updating metadata:", error));
                        });
                        fetchUpdatedScores();
                    } else {
                        console.warn("Full analysis failed.");
                    }
                })
                .catch((error) => {
                    console.error("Error in full analysis:", error);
                    setLoading(false); 
                });

                /*setAnalysisScreeningLoading(true); 
                const screeningAnalysisPromise = analyseBloodTestForScreening(text)
                    .then((screeningAnalysisPromise) => {
                        setAnalysisScreeningLoading(false);
                        setScreeningResult(screeningAnalysisPromise);

                        if (screeningAnalysisPromise) {
                            getMetadata(fileRef).then((fileMetadata) => {
                                updateMetadata(fileRef, {
                                    customMetadata: {
                                        ...fileMetadata.customMetadata,
                                        screening: screeningAnalysisPromise,
                                    },
                                }).catch((error) => console.error("Error updating metadata:", error));
                            });
                        } else {
                            console.warn("Screening analysis failed.");
                        }
                    })
                    .catch((error) => {
                        console.error("Error in screening analysis:", error);
                        setAnalysisScreeningLoading(false); 
                    });*/
        
            setSmartReportLoading(true);
            const smartReportPromise = generateSmartReport(text)
                .then(async (smartReportResult) => {
                    if (smartReportResult) {
                        await saveSmartReportToStorage(smartReportResult, userId, selectedFileData.name);
                        await new Promise(resolve => setTimeout(resolve, 1000));
                        await fetchAllSmartReports(userId);
                        
                        setSmartReport(smartReportResult);
                    } else {
                        console.warn("Smart report failed.");
                    }
                    setSmartReportLoading(false);
                })
                .catch((error) => {
                    console.error("Error in smart report:", error);
                    setSmartReportLoading(false);
                });
        
            await Promise.all([fullAnalysisPromise, smartReportPromise]);

        } catch (error) {
            if (error.response?.status === 403 || error.status === 403) {
                setShowUpgradeModal(true);
                setAnalysisPerformed(false);
            } else {
                toast.error(t('erroAnalise'));
            }
            setAnalysisPerformed(false);
        } finally {
            setLoading(false);
            setSmartReportLoading(false);
        }
    };

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
        setUploadSuccess(false);
        setShowNewFileModal(true);
    };
    
    const handleUpload = async () => {
        try {
            if (!selectedFile) {
                return;
            }

            const userId = auth.currentUser.uid;
            const storageRef = ref(storage, `bloodTestResults/${userId}/${selectedFile.name}`);
            await uploadBytes(storageRef, selectedFile);

            setUploadSuccess(true);
            setShowNewFileModal(false);
            
            toast.success(t('ficheiroCarregado'));
            console.log('File uploaded successfully', storageRef);

            window.location.reload();
        } catch (error) {
            toast.error(t('erroFicheiroAgain'))
        }
    };

    const handleNewFileClick = () => {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = '.pdf';
        input.onchange = handleFileChange;
        input.click();
    };

    const handleLogout = async () => {
        try {
            await signOut(auth);
            toast.success(t('logout'));
            navigate('/');
        } catch (error) {
            toast.error(t('logoutError'));
        }
    };

    const handleSideBar = () => {
        setNav(!nav);
    };

    useEffect(() => {
        const closeBarOnResize = () => {
            if (window.innerWidth > 768 && nav) {
                setNav(false);
            }
        };

        window.addEventListener('resize', closeBarOnResize);

        return () => {
            window.removeEventListener('resize', closeBarOnResize);
        };
    }, [nav]);

    const toggleResults = () => {
        setResultsVisible(!resultsVisible);
    }

    const convertPDFToText = async (pdfURL) => {
        try {
            const response = await fetch(`${BASE_URL}/extract-text`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({ pdfURL }),
              });

              if (response.status === 400) {
                const { message } = await response.json();
                const password = prompt(message);
                
                if (password) {
                    const unlockResponse = await fetch(`${BASE_URL}/submit-password`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ pdfURL, password }),
                    });
    
                    if (unlockResponse.ok) {
                        const unlockData = await unlockResponse.json();
                        return unlockData.extractedText;
                    } else {
                        console.error('Failed to unlock PDF:', unlockResponse.statusText);
                        throw new Error('Failed to unlock PDF');
                    }
                } else {
                    throw new Error('Password input was canceled');
                }
              }
      
              const data = await response.json();
              return data.text;
            } catch (error) {
              console.error('Error fetching text:', error);
            }
    };

    function getLanguageFromDomain() {
        const domain = window.location.hostname;
  
        if (domain === 'bloodflow.eu' || domain === 'www.bloodflow.eu') {
            return 'en';
        } else if (domain === 'bloodflow.pt' || domain === 'www.bloodflow.pt') {
            return 'pt';
        }

        return 'en';
    }

    const analyseBloodTest = async (text) => {
        const language = getLanguageFromDomain();
        const userName = userData.username;
        const age = userAge;
        const medicalCondition = userData.medicalCondition;
        const userID = user.uid;
        const analysisType = 'full';
    
        try {
            const response = await axios.post(`${BASE_URL}/analyze-blood-test`, {
                text,
                userName,
                age,
                medicalCondition,
                language,
                userID,
                analysisType,
                
            });
    
            return response.data.analysis;
        } catch (error) {
            throw error; 
        }
    };

    /*
    const analyseBloodTestForScreening = async (text) => {
        const language = getLanguageFromDomain();
        const userName = userData.username;
        const age = userAge;
        const medicalCondition = userData.medicalCondition;
        const userID = user.uid;

        try {
            const response = await axios.post(`${BASE_URL}/analyze-blood-test-screening`, {
                text,
                userName,
                age,
                medicalCondition,
                language,
                userID,
            });

            return response.data.analysis;
        } catch (error) {
            throw error;
        }
    };
    */

    const generateSmartReport = async (text) => {
        try {
            const language = getLanguageFromDomain();
            const userID = user.uid;
            const analysisType = 'smart';
    
            const response = await fetch(`${BASE_URL}/generate-smart-report`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ text, language, userID, analysisType }),
            });
    
            if (!response.ok) {
                console.error('Failed to generate smart report');
                return null;
            }
    
            const data = await response.json();
            return data.analysisSmartReport;
    
        } catch (error) {
            console.error('Error generating smart report:', error);
            return null;
        }
    };

    const handleTabChange = (tab) => {
        setActiveTab(tab);
      };

    const extractScoreFromAnalysis = (analysis) => {
        const match = analysis.match(/(?:\*\*)?Score(?:\*\*)?:\s*(\d+(\.\d+)?)/i);
        return match ? parseFloat(match[1]) : 0;
    };

    /*
    useEffect(() => {
        if (screeningResult && screeningIndex < screeningResult.length) {
            const intervalId = setInterval(() => {
                setScreeningAnalysis((prev) => prev + screeningResult.charAt(screeningIndex));
                setScreeningIndex((prev) => prev + 1);
            }, 10);

            if (screeningIndex === screeningResult.length) {
                clearInterval(intervalId);
            }

            return () => clearInterval(intervalId);
        }
    }, [screeningIndex, screeningResult]);
    */


    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const handleDeleteClick = () => { 
        setShowModal(true);
      };

    const selectAnotherFile = (files) => {
        if (files.length > 0) {
            const fileName = files[files.length - 1];
            handleFileClick(fileName);
        } else {
            setSelectedFileData(null);
        }
    };  

    const handleCancelFileUpload = () => {  
        setSelectedFile(null);
        setUploadSuccess(false);
        setShowNewFileModal(false);
    };    

    const confirmDeleteFile = async () => {
        const userId = user.uid;
        const fileRef = ref(storage, `bloodTestResults/${userId}/${selectedFileData.name}`);
        const fileRefSmartReport = ref(storage, `smartReportsJson/${userId}/${selectedFileData.name}_smartReport.json`);

        try {
            await deleteObject(fileRef);
            await deleteObject(fileRefSmartReport);
            toast.success(t('ficheiroEliminado'));
            setUserFiles((prevFiles) => prevFiles.filter(file => file !== selectedFileData.name));
            setFileScores((prevScores) => prevScores.filter(file => file.name !== selectedFileData.name));
            await fetchAllSmartReports(userId);
            selectAnotherFile(userFiles.filter(file => file !== selectedFileData.name));
            setShowModal(false);
        } catch (error) {
            toast.error(t('ficheiroEliminadoError'));
        }
      };

    const handleEditClick = () => {
        setShowEditPopup(true);
    };
    
    const handleClosePopup = () => {
        setShowEditPopup(false);
    };
    
    return (
        loadingWindow ? (
            <Spinner />
          ) : (
                <div className="flex h-screen">
                    {/* Sidebar */}
                    <div id="Sidebar" className="bg-white h-full md:w-1/5 sm:w-0 w-0 relative shadow-lg flex flex-col">
                        <div className="hidden md:flex flex-col justify-start items-start w-full flex-grow overflow-hidden">
                            <div className="w-full px-6 border-b border-gray-100 flex-shrink-0">
                                <img 
                                    className="w-32 mx-auto transition-transform duration-300 hover:scale-105" 
                                    src={BloodFlowLogo} 
                                    alt="BloodFlow Logo" 
                                />
                            </div>

                            {userData !== null && (
                                <div className="w-full px-6 py-4 border-b border-gray-100 flex-shrink-0">
                                    <div className="border-2 border-[#f3c8c8] bg-gradient-to-br from-white via-red-50 to-white rounded-lg p-4 shadow-sm">
                                        <h3 className="text-base font-bold text-gray-800 mb-2">Profile</h3>
                                        <div className="space-y-1">
                                            <p className="text-xs text-gray-600">
                                                <span className="font-semibold">{t('nomeDash')}</span> {userData.username}
                                            </p>
                                            <p className="text-xs text-gray-600">
                                                <span className="font-semibold">{t('Idade')}</span> {userAge} Anos
                                            </p>
                                            <p className="text-xs text-gray-600">
                                                <span className="font-semibold">{t('condicaoMedicaDash')}</span> 
                                                {userData.medicalCondition ? userData.medicalCondition : t('nenhuma')}
                                            </p>
                                            <p className="text-xs text-gray-600 mt-2">
                                                <span className="font-semibold">{t('subscriptionStatus')}</span> 
                                                <span className={
                                                    userData.subscriptionStatus === 'pro' || userData.subscriptionStatus === 'pending_cancellation'
                                                        ? "text-red-600 font-bold" 
                                                        : "text-gray-500"
                                                }>
                                                    {userData.subscriptionStatus === 'pro'|| userData.subscriptionStatus === 'pending_cancellation'
                                                        ? 'Pro'
                                                        : 'Free'}
                                                </span>
                                            </p>
                                        </div>
                                        <div className="flex flex-col justify-center mt-2">
                                            <button 
                                                onClick={handleEditClick} 
                                                className="text-[#ce3d3d] font-bold text-xs hover:underline transition-colors"
                                            >
                                                {t('editarInfoDash')}
                                            </button>

                                            <button 
                                                onClick={() => navigate('/dashboard/subscription')} 
                                                className="text-gray-800 font-bold text-xs hover:underline transition-colors"
                                            >
                                                {t('manageSubscription')}
                                            </button>
                                        </div>
                                    </div>
                                    <EditPersonalInfo show={showEditPopup} onClose={handleClosePopup} userInfo={userData} userId={user.uid}/>
                                </div>
                            )}

                            {/* Stored Results Section */}
                            <div className="w-full px-6 py-4 border-b border-gray-100 flex-shrink-0">
                                <div className="flex items-center justify-between mb-2">
                                    <button 
                                        onClick={toggleResults} 
                                        className="uppercase text-xs font-bold text-gray-800 hover:text-[#ce3d3d] transition-colors"
                                    >
                                        {t('resultsStored')}
                                    </button>
                                    <button onClick={toggleResults} className="text-gray-600 focus:outline-none">
                                        <svg 
                                            className={`h-4 w-4 transform transition-transform ${resultsVisible ? 'rotate-180' : ''}`} 
                                            fill="none" 
                                            viewBox="0 0 24 24" 
                                            stroke="#ce3d3d"
                                            strokeWidth={2}
                                        >
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
                                        </svg>
                                    </button>
                                </div>
                                
                                <div 
                                    className={`${resultsVisible ? '' : 'hidden'} 
                                        max-h-52 overflow-y-auto 
                                        scrollbar-thin scrollbar-thumb-[#ce3d3d] scrollbar-track-gray-100 
                                        pr-2`}
                                >
                                    {userFiles.length > 0 ? (
                                        <ul id="resultsList" className='space-y-1'>
                                            {userFiles.slice().reverse().map((result, index) => (
                                                <li 
                                                    key={index} 
                                                    onClick={() => handleFileClick(result)} 
                                                    className={`
                                                        px-2 py-1.5 rounded-md cursor-pointer text-xs
                                                        transition-all duration-300 
                                                        ${selectedFileData?.name === result 
                                                            ? 'bg-[#ce3d3d] text-white' 
                                                            : 'hover:bg-gray-100 text-gray-700'}
                                                    `}
                                                >
                                                    {result}
                                                </li>
                                            ))}
                                        </ul>
                                    ) : (
                                        <div className="py-6 px-2">
                                            <div className="flex flex-col items-center text-center">
                                                <div className="relative mb-3">
                                                    <div className="absolute inset-0 bg-red-100 rounded-full blur-lg animate-pulse"></div>
                                                    <div className="relative bg-white p-2 rounded-lg shadow-sm border border-red-100">
                                                        <Inbox className="w-4 h-4 text-red-500/70" />
                                                    </div>
                                                </div>
                                                
                                                <p className="text-xs text-gray-600 font-medium">
                                                {t('noStoredResults')}
                                                </p>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>

                            {/* Action Buttons Section */}
                            <div className="w-full px-6 py-4 mt-auto flex-shrink-0">
                                <div className="space-y-3">
                                    <button 
                                        className="w-full bg-gradient-to-r from-[#d73838] to-[#a31d1d] text-sm rounded-xl font-bold py-2.5 text-white hover:from-[#a31d1d] hover:to-[#d73838] transition-all duration-300 transform hover:-translate-y-1 shadow-sm hover:shadow-md disabled:opacity-75 disabled:hover:transform-none" 
                                        onClick={handleNewFileClick}
                                    >
                                        <span>{t('newFile')}</span>
                                    </button>

                                    {selectedFile && !uploadSuccess && showNewFileModal && (
                                        <NewFilePopup 
                                            show={showNewFileModal} 
                                            onClose={handleCancelFileUpload} 
                                            onConfirm={handleUpload} 
                                            fileName={selectedFile.name}
                                        />
                                    )}

                                    <button 
                                        className="w-full text-sm rounded-xl font-bold py-2.5 text-white bg-gray-800 hover:bg-black transition-all duration-300 transform hover:-translate-y-1 shadow-sm hover:shadow-md flex items-center justify-center space-x-2" 
                                        onClick={handleLogout}
                                    >
                                        {t('logoutDash')}
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className={`${nav ? 'fixed inset-0' : 'hidden'} bg-white flex flex-col z-50 overflow-hidden`}>
                            {/* Close Button */}
                            <div className="absolute top-4 right-4 z-10">
                                <button 
                                    onClick={handleSideBar} 
                                    className="p-2 rounded-full bg-gray-100 hover:bg-gray-200 transition-colors"
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </button>
                            </div>

                            {/* Logo */}
                            <div className="w-full px-6 pt-2 pb-6 flex justify-center">
                                <img 
                                    className="w-32 transition-transform duration-300 hover:scale-105" 
                                    onClick={handleSideBar} 
                                    src={BloodFlowLogo} 
                                    alt="Logo" 
                                />
                            </div>

                            {/* User Profile Section */}
                            {userData && (
                                <div className="w-[70%] mx-auto px-6 py-4 border-b border-gray-100 -mt-10">
                                    <div className="border-2 border-[#f3c8c8] bg-gradient-to-br from-white via-red-50 to-white rounded-lg p-4 shadow-sm text-center">
                                        <h3 className="text-base font-bold text-gray-800 mb-2">Profile</h3>
                                        <div className="space-y-1">
                                            <p className="text-xs text-gray-600">
                                                <span className="font-semibold">{t('nomeDash')}</span> {userData.username}
                                            </p>
                                            <p className="text-xs text-gray-600">
                                                <span className="font-semibold">{t('Idade')}</span> {userAge} Anos
                                            </p>
                                            <p className="text-xs text-gray-600">
                                                <span className="font-semibold">{t('condicaoMedicaDash')}</span> 
                                                {userData.medicalCondition ? userData.medicalCondition : t('nenhuma')}
                                            </p>
                                            <p className="text-xs text-gray-600 mt-2">
                                                <span className="font-semibold">{t('subscriptionStatus')}</span> 
                                                <span className={
                                                    userData.subscriptionStatus === 'pro' 
                                                    ? "text-red-600 font-bold" 
                                                    : "text-gray-500"
                                                }>
                                                    {userData.subscriptionStatus === 'pro' ? 'Pro' : 'Free'}
                                                </span>
                                            </p>
                                        </div>
                                        <div className="flex flex-col justify-center mt-2">
                                            <button 
                                                onClick={handleEditClick} 
                                                className="text-[#ce3d3d] font-bold text-xs hover:underline transition-colors"
                                            >
                                                {t('editarInfoDash')}
                                            </button>

                                            <button 
                                                onClick={() => navigate('/dashboard/subscription')} 
                                                className="text-gray-800 font-bold text-xs hover:underline transition-colors"
                                            >
                                                {t('manageSubscription')}
                                            </button>
                                        </div>
                                    </div>
                                    <EditPersonalInfo show={showEditPopup} onClose={handleClosePopup} userInfo={userData} userId={user.uid}/>
                                </div>
                            )}

                            {/* Stored Results Section */}
                            <div className="w-full px-6 py-4 border-b border-gray-100">
                                <div className="flex items-center justify-between mb-2">
                                    <button 
                                        onClick={toggleResults} 
                                        className="uppercase text-xs font-bold text-gray-800 hover:text-[#ce3d3d] transition-colors"
                                    >
                                        {t('resultsStored')}
                                    </button>
                                    <button onClick={toggleResults} className="text-gray-600 focus:outline-none">
                                        <svg 
                                            className={`h-4 w-4 transform transition-transform ${resultsVisible ? 'rotate-180' : ''}`} 
                                            fill="none" 
                                            viewBox="0 0 24 24" 
                                            stroke="#ce3d3d"
                                            strokeWidth={2}
                                        >
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
                                        </svg>
                                    </button>
                                </div>
                                
                                <div 
                                    className={`${resultsVisible ? '' : 'hidden'} 
                                        max-h-36 overflow-y-auto 
                                        scrollbar-thin scrollbar-thumb-[#ce3d3d] scrollbar-track-gray-100 
                                        pr-2`}
                                >
                                    {userFiles.length > 0 ? (
                                        <ul id="resultsList" className='space-y-1'>
                                            {userFiles.slice().reverse().map((result, index) => (
                                                <li 
                                                    key={index} 
                                                    onClick={() => handleFileClick(result)} 
                                                    className={`
                                                        px-2 py-1.5 rounded-md cursor-pointer text-xs
                                                        transition-all duration-300 
                                                        ${selectedFileData?.name === result 
                                                            ? 'bg-[#ce3d3d] text-white' 
                                                            : 'hover:bg-gray-100 text-gray-700'}
                                                    `}
                                                >
                                                    {result}
                                                </li>
                                            ))}
                                        </ul>
                                    ) : (
                                        <div className="py-6 px-2">
                                            <div className="flex flex-col items-center text-center">
                                                <div className="relative mb-3">
                                                    <div className="absolute inset-0 bg-red-100 rounded-full blur-lg animate-pulse"></div>
                                                    <div className="relative bg-white p-2 rounded-lg shadow-sm border border-red-100">
                                                        <Inbox className="w-4 h-4 text-red-500/70" />
                                                    </div>
                                                </div>
                                                
                                                <p className="text-xs text-gray-600 font-medium">
                                                {t('noStoredResults')}
                                                </p>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>

                            {/* Action Buttons Section */}
                            <div className="w-full px-6 py-4 mt-auto">
                                <div className="space-y-3">
                                    <button 
                                        className="w-full bg-gradient-to-r from-[#d73838] to-[#a31d1d] text-sm rounded-xl font-bold py-2.5 text-white hover:from-[#a31d1d] hover:to-[#d73838] transition-all duration-300 transform hover:-translate-y-1 shadow-sm hover:shadow-md disabled:opacity-75 disabled:hover:transform-none" 
                                        onClick={handleNewFileClick}
                                    >
                                        <span>{t('newFile')}</span>
                                    </button>

                                    {selectedFile && !uploadSuccess && showNewFileModal && (
                                        <NewFilePopup 
                                            show={showNewFileModal} 
                                            onClose={handleCancelFileUpload} 
                                            onConfirm={handleUpload} 
                                            fileName={selectedFile.name}
                                        />
                                    )}

                                    <button 
                                        className="w-full text-sm rounded-xl font-bold py-2.5 text-white bg-gray-800 hover:bg-black transition-all duration-300 transform hover:-translate-y-1 shadow-sm hover:shadow-md flex items-center justify-center space-x-2" 
                                        onClick={handleLogout}
                                    >
                                        {t('logoutDash')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>


                    {/* Main Container */}
                    <div className="main-container flex-1 bg-gray-100 overflow-y-auto pt-6 px-4 md:px-6 md:w-4/5">
                        {/* Title */}
                        <div className="bg-white w-full mb-6 shadow-sm hover:shadow-md transition-all duration-300 rounded-xl overflow-hidden">
                            <div className="relative">
                                <div className="absolute top-0 left-0 w-1 h-full bg-gradient-to-b from-[#ce3d3d] to-[#a31d1d]"></div>
                                <div className='flex justify-between items-center p-6'>
                                    {userData !== null && (
                                        <div className="flex flex-col w-full">
                                            <div className="flex justify-between items-center">
                                                <h1 className="font-bold text-lg md:text-2xl text-gray-800 mb-1">
                                                    {t('dasboardTitle')} 
                                                    <span className="text-[#ce3d3d] ml-1">{userData.username}</span>
                                                </h1>
                                                
                                                {userData.subscriptionStatus !== 'free' ? (
                                                    <span className="bg-red-500 text-white text-center text-xs font-bold px-3 py-1 rounded-full mr-3 sm:mr-0">
                                                        {t('planoPro')}
                                                    </span>
                                                ) : (
                                                    <div className="flex flex-col md:flex-row items-center space-y-2 md:space-y-0 md:space-x-3">
                                                        <span className="bg-gray-500 text-white text-center text-xs font-bold px-3 py-1 rounded-full">
                                                            {t('freePlan')}
                                                        </span>
                                                        <span className="text-xs sm:text-sm text-gray-600">
                                                            {`${5 - (userData.analysisCount || 0)} ${t('analysesRemaining')}`}
                                                        </span>
                                                        {(userData.analysisCount || 0) >= 5 && (
                                                            <button 
                                                                onClick={() => navigate('/dashboard/subscription')}
                                                                className="text-xs text-red-600 hover:underline"
                                                            >
                                                                {t('upgradeToProNow')}
                                                            </button>
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                    <div className="md:hidden" onClick={handleSideBar}>
                                        {nav ? <AiOutlineClose size={20} className="text-gray-600"/> : <AiOutlineMenu size={20} className="text-gray-600"/>}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Selected file container */}
                        <div className="bg-white mb-6 shadow-sm hover:shadow-md transition-all duration-300 rounded-xl overflow-hidden">
                        {selectedFileData && selectedFile == null ? (
                            <div className="relative">
                                <div className="absolute top-0 left-0 w-1 h-full bg-gradient-to-b from-[#ce3d3d] to-[#a31d1d]"></div>
                                <div className="p-6">
                                    <div className="flex flex-col md:flex-row">
                                        {/* Left container */}
                                        <div className="w-full md:w-2/3 p-2 md:p-6">
                                            <div className="bg-gradient-to-br from-white via-red-50 to-white rounded-xl p-6 flex-grow flex items-center relative group hover:shadow-lg transition-all duration-300">
                                                <div className="absolute inset-0 border-2 border-[#ce3d3d] opacity-10 rounded-xl group-hover:opacity-20 transition-opacity duration-300"></div>
                                                <div className="flex-grow space-y-6">
                                                    <div>
                                                        <h1 className="text-sm md:text-xl font-bold text-gray-800 mb-2">{t('selectedFileForAnalysis')}</h1>
                                                        <div className="h-1 w-20 bg-gradient-to-r from-[#ce3d3d] to-[#a31d1d] rounded-full"></div>
                                                    </div>
                                                    <div className="space-y-4">
                                                        <div className="flex items-center space-x-3">
                                                            <div className="w-2 h-2 bg-[#ce3d3d] rounded-full"></div>
                                                            <p className="text-xs md:text-base text-gray-700">
                                                                <span className="font-semibold mr-2">{t('nomeFile')}</span>
                                                                <span className="font-medium">{selectedFileData.name}</span>
                                                            </p>
                                                        </div>
                                                        <div className="flex items-center space-x-3">
                                                            <div className="w-2 h-2 bg-[#ce3d3d] rounded-full"></div>
                                                            <p className="text-xs md:text-base text-gray-700">
                                                                <span className="font-semibold mr-2">{t('storedAt')}</span>
                                                                <span className="font-medium">{formatDate(selectedFileData.timeCreated)}</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="pt-2">
                                                        <p className="text-xs text-gray-500 font-medium inline-flex items-center">
                                                            <svg className="w-4 h-4 mr-2 text-[#ce3d3d]" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                            </svg>
                                                            {t('textFileSelected')}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="transform group-hover:scale-105 transition-all duration-500">
                                                    <img className="w-56 opacity-90" src={PDF_ICON} alt="PDF Icon"/>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Right container */}
                                        <div className="w-full md:w-1/3 p-2 md:p-6 md:pl-4 flex flex-col items-center justify-center bg-white mb-6 sm:mb-0">
                                            <div className="w-full max-w-xs space-y-6">
                                                <div className="text-center">
                                                    <p className="font-bold text-gray-800 text-lg md:text-xl mb-2">{t('startAnalysis')}</p>
                                                    <div className="h-1 w-16 bg-gradient-to-r from-[#ce3d3d] to-[#a31d1d] mx-auto rounded-full"></div>
                                                </div>
                                                <div className="space-y-3">
                                                    <button 
                                                        onClick={handleAnalyzeClick} 
                                                        disabled={loading} 
                                                        className="w-full bg-gradient-to-r from-[#d73838] to-[#a31d1d] text-sm rounded-xl font-bold py-4 text-white hover:from-[#a31d1d] hover:to-[#d73838] transition-all duration-300 transform hover:-translate-y-1 shadow-sm hover:shadow-md disabled:opacity-75 disabled:hover:transform-none"
                                                    >
                                                        {loading ? (
                                                            <div className="flex items-center justify-center space-x-2">
                                                                <svg className="animate-spin h-5 w-5 text-white" viewBox="0 0 24 24">
                                                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" fill="none" />
                                                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                                                                </svg>
                                                                <span>{t('analysing')}</span>
                                                            </div>
                                                        ) : (
                                                            t('analise')
                                                        )}
                                                    </button>
                                                    <button 
                                                        onClick={handleDeleteClick} 
                                                        className="w-full text-sm rounded-xl font-bold py-4 text-white bg-gray-800 hover:bg-black transition-all duration-300 transform hover:-translate-y-1 shadow-sm hover:shadow-md flex items-center justify-center space-x-2"
                                                    >
                                                        <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                                        </svg>
                                                        <span>{t('deleteButton')}</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="bg-white shadow-sm hover:shadow-md transition-all duration-300 rounded-xl overflow-hidden">
                                    <div className="relative">
                                        <div className="absolute top-0 left-0 w-1 h-full bg-gradient-to-b from-[#ce3d3d] to-[#a31d1d]"></div>
                                        <div className="p-8">
                                            <div className="relative overflow-hidden bg-gradient-to-b from-gray-50 to-white rounded-2xl">
                                                {/* Decorative elements */}
                                                <div className="absolute top-0 left-0 w-full h-1 bg-gradient-to-r from-red-500/10 via-red-500/20 to-red-500/10"></div>
                                                <div className="absolute top-1 left-10 w-20 h-20 bg-red-500/5 rounded-full blur-2xl"></div>
                                                <div className="absolute bottom-10 right-10 w-32 h-32 bg-gray-100 rounded-full blur-3xl"></div>
                                                
                                                {/* Content container */}
                                                <div className="relative px-6 py-12 sm:px-12 sm:py-16">
                                                <div className="flex flex-col items-center text-center max-w-sm mx-auto">
                                                    {/* Icon container with animation */}
                                                    <div className="relative mb-6">
                                                    <div className="absolute inset-0 bg-red-100 rounded-full blur-xl animate-pulse"></div>
                                                    <div className="relative bg-white shadow-lg rounded-2xl p-4 border border-red-100">
                                                        <FileX2 className="w-8 h-8 text-red-500" />
                                                    </div>
                                                    </div>
                                                    
                                                    {/* Text content */}
                                                    <h2 className="text-xl font-semibold text-gray-900 mb-3">
                                                    {t('selectedFileForAnalysis')}
                                                    </h2>
                                                    <p className="text-gray-600 mb-8">
                                                    {t('noData')}
                                                    </p>
                                                    
                                                    {/* Action hint */}
                                                    <div className="flex items-center space-x-2 text-sm text-gray-500">
                                                    <Upload className="w-4 h-4" />
                                                    <span>{t('upFile')}</span>
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        )}

                        {/* Delete Confirmation Modal */}
                        {showModal && (
                            <DeletePopup
                                show={showModal}
                                onClose={() => setShowModal(false)}
                                onConfirm={confirmDeleteFile}
                            >
                                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                    <div className="sm:flex sm:items-start">
                                        <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                            <svg className="h-6 w-6 text-red-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                                            </svg>
                                        </div>
                                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                            <h3 className="text-lg leading-6 font-medium text-gray-900">
                                                Delete File
                                            </h3>
                                            <div className="mt-2">
                                                <p className="text-sm text-gray-500">
                                                    Are you sure you want to delete this file? This action cannot be undone.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </DeletePopup>
                        )}
                    </div>

                    {/* Test Score container */}
                        <div className="bg-white mb-6 shadow-sm hover:shadow-md transition-all duration-300 rounded-xl overflow-hidden">
                            {selectedFileData && selectedFile == null ? (
                                <div className="relative">
                                    <div className="absolute top-0 left-0 w-1 h-full bg-gradient-to-b from-[#ce3d3d] to-[#a31d1d]"></div>
                                    <div className="p-6">
                                        <div className="text-center mb-6">
                                            <h1 className="text-lg md:text-xl font-bold text-gray-800">{t('scoreProgress')}</h1>
                                            <div className="h-1 w-24 bg-gradient-to-r from-[#ce3d3d] to-[#a31d1d] mx-auto mt-2 rounded-full"></div>
                                        </div>
                                        
                                        <div className="flex flex-col -mx-8 md:flex-row justify-center items-center">
                                            <div className="w-full md:w-1/3 flex flex-col justify-center items-center">
                                                <div className="mb-2 md:mb-4">
                                                    <CircularChart score={ fileScores.length > 0 ? fileScores[fileScores.length - 1].score : fileScores[fileScores.length - 2].score } />
                                                </div>
                                                <p className="text-center text-sm md:text-lg">{t('lastScoreResult')}</p>
                                            </div>
                                            <div className="w-full -ml-10 md:w-2/3 py-2 md:py-4">
                                                <MyBarChart data={fileScores} selectedFileMetadata={selectedFileData} onBarClick={handleFileClick} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (

                                <div className="bg-white shadow-sm hover:shadow-md transition-all duration-300 rounded-xl overflow-hidden">
                                    <div className="relative">
                                        <div className="absolute top-0 left-0 w-1 h-full bg-gradient-to-b from-[#ce3d3d] to-[#a31d1d]"></div>
                                            <div className="p-6">
                                                <div className="bg-gradient-to-b from-white to-gray-50 rounded-2xl overflow-hidden">
                                                    {/* Header */}
                                                    <div className="text-center pt-8 pb-6 px-4">
                                                        <h2 className="text-xl font-semibold text-gray-900 mb-2">{t('scoreProgress')}</h2>
                                                        <div className="h-1 w-16 bg-gradient-to-r from-red-500 to-red-600 mx-auto rounded-full"></div>
                                                    </div>

                                                    {/* Empty state content */}
                                                    <div className="px-6 pb-10">
                                                        <div className="flex flex-col items-center">
                                                            {/* Decorative circles */}
                                                            <div className="relative w-full h-48 mb-6">
                                                            {/* Background elements */}
                                                                <div className="absolute left-1/4 top-1/2 w-24 h-24 bg-red-50 rounded-full blur-xl"></div>
                                                                <div className="absolute right-1/4 top-1/3 w-32 h-32 bg-gray-100 rounded-full blur-2xl"></div>
                                                                
                                                                {/* Icon grid */}
                                                                <div className="relative h-full flex items-center justify-center">
                                                                    <div className="grid grid-cols-2 gap-4">
                                                                    <div className="bg-white p-3 rounded-xl shadow-sm border border-gray-100">
                                                                        <Trophy className="w-6 h-6 text-red-500/70" />
                                                                    </div>
                                                                    <div className="bg-white p-3 rounded-xl shadow-sm border border-gray-100">
                                                                        <PieChart className="w-6 h-6 text-red-500/70" />
                                                                    </div>
                                                                    <div className="bg-white p-3 rounded-xl shadow-sm border border-gray-100">
                                                                        <TrendingUp className="w-6 h-6 text-red-500/70" />
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            {/* Text content */}
                                                            <div className="text-center max-w-sm">
                                                                <p className="text-gray-600 mb-2">{t('noData')}</p>
                                                                <p className="text-sm text-gray-500">{t('completeAnalysis')}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            )}
                        </div>

                        {/* Biomarker Tracking Chart */}
                        <div className="bg-white mb-6 shadow-sm hover:shadow-md transition-all duration-300 rounded-xl overflow-hidden">
                            <div className="relative">
                                <div className="absolute top-0 left-0 w-1 h-full bg-gradient-to-b from-[#ce3d3d] to-[#a31d1d]"></div>
                                <div className="p-6">
                                    {selectedFileData && selectedFile == null ? (
                                        <div>
                                            <div className="text-center mb-6">
                                                <h1 className="text-lg md:text-xl font-bold text-gray-800">Biomarker Tracking</h1>
                                                <div className="h-1 w-24 bg-gradient-to-r from-[#ce3d3d] to-[#a31d1d] mx-auto mt-2 rounded-full"></div>
                                            </div>
                                            <div className="-mx-4 md:py-4">
                                                <BiomarkerChart
                                                    data={biomarkerData}
                                                    biomarkerRanges={biomarkerRanges}
                                                    isLoading={chartBiomarkerLoading}
                                                    error={null}
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="p-6">
                                            <div className="bg-gradient-to-br from-white via-red-50/20 to-white rounded-2xl overflow-hidden">
                                                {/* Header */}
                                                <div className="text-center pt-8 pb-6 px-4">
                                                    <h2 className="text-xl font-semibold text-gray-900 mb-2">Biomarker Tracking</h2>
                                                    <div className="h-1 w-16 bg-gradient-to-r from-red-500 to-red-600 mx-auto rounded-full"></div>
                                                </div>

                                                {/* Main content */}
                                                <div className="px-6 pb-10">
                                                    <div className="flex flex-col items-center">
                                                        {/* Animated graph placeholder */}
                                                        <div className="relative w-full max-w-md h-40 mb-8">
                                                        {/* Background decorative elements */}
                                                        <div className="absolute inset-0">
                                                            <div className="absolute left-0 bottom-0 w-full h-24 bg-gradient-to-t from-gray-50/80 to-transparent"></div>
                                                            <div className="absolute left-1/4 top-1/2 w-24 h-24 bg-red-50 rounded-full blur-xl"></div>
                                                            <div className="absolute right-1/4 bottom-1/3 w-32 h-32 bg-gray-100 rounded-full blur-2xl"></div>
                                                        </div>

                                                        {/* Placeholder graph lines */}
                                                        <div className="relative h-full flex items-end justify-between px-4">
                                                            {[...Array(6)].map((_, i) => (
                                                            <div key={i} className="w-1 bg-red-100 rounded-t-full animate-pulse" 
                                                                style={{
                                                                    height: `${20 + Math.random() * 60}%`,
                                                                    animationDelay: `${i * 0.2}s`
                                                                }}>
                                                            </div>
                                                            ))}
                                                        </div>
                                                        </div>

                                                        {/* Icons grid */}
                                                        <div className="grid grid-cols-4 gap-4 mb-8">
                                                        <div className="p-3 bg-white rounded-xl shadow-sm border border-gray-100">
                                                            <Activity className="w-5 h-5 text-red-500/70" />
                                                        </div>
                                                        <div className="p-3 bg-white rounded-xl shadow-sm border border-gray-100">
                                                            <LineChart className="w-5 h-5 text-red-500/70" />
                                                        </div>
                                                        <div className="p-3 bg-white rounded-xl shadow-sm border border-gray-100">
                                                            <Target className="w-5 h-5 text-red-500/70" />
                                                        </div>
                                                        <div className="p-3 bg-white rounded-xl shadow-sm border border-gray-100">
                                                            <Heart className="w-5 h-5 text-red-500/70" />
                                                        </div>
                                                        </div>

                                                        {/* Text content */}
                                                        <div className="text-center max-w-sm">
                                                            <p className="text-gray-600 mb-2">{t('noData')}</p>
                                                            <p className="text-sm text-gray-500">{t('upFileBio')}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        {/* Analysis Results Container */}
                        <div
                            id="analysisContainer"
                            ref={analysisContainerRef}
                            className="bg-white mb-6 shadow-sm hover:shadow-md transition-all duration-300 rounded-xl overflow-hidden"
                        >
                            <div className="relative">
                                <div className="absolute top-0 left-0 w-1 h-full bg-gradient-to-b from-[#ce3d3d] to-[#a31d1d]"></div>
                                <div className="p-6">
                                    {/* No Data message */}
                                    {!analysisPerformed && !loading && !smartReportLoading && (
                                        <div className="p-6">
                                        <div className="bg-gradient-to-br from-white via-red-50/10 to-white rounded-2xl overflow-hidden">
                                          {/* Header */}
                                          <div className="text-center pt-8 pb-6">
                                            <h1 className="text-xl font-bold text-gray-800 mb-2">
                                              {t("yourAnalysisBy")}{" "}
                                              <span className="bg-clip-text text-transparent bg-gradient-to-r from-red-500 to-red-600">
                                                {t("bloodFlowAI")}
                                              </span>
                                            </h1>
                                            <div className="h-1 w-16 bg-gradient-to-r from-red-500 to-red-600 mx-auto rounded-full"></div>
                                          </div>
                                  
                                          {/* Main content */}
                                          <div className="px-6 pb-12">
                                            {/* Analysis steps visualization */}
                                            <div className="max-w-2xl mx-auto mb-8">
                                              <div className="grid grid-cols-4 gap-4">
                                                {[
                                                  { icon: FileText, label: "Upload", delay: "0" },
                                                  { icon: Search, label: "Process", delay: "150" },
                                                  { icon: Brain, label: "Analyze", delay: "300" },
                                                  { icon: ChartBar, label: "Results", delay: "450" }
                                                ].map((step, index) => (
                                                  <div key={index} className="flex flex-col items-center" 
                                                       style={{ animationDelay: `${step.delay}ms` }}>
                                                    {/* Icon container */}
                                                    <div className="relative mb-3">
                                                      <div className="absolute inset-0 bg-red-100 rounded-full blur-lg animate-pulse"></div>
                                                      <div className="relative bg-white p-3 rounded-xl shadow-sm border border-red-100">
                                                        <step.icon className="w-6 h-6 text-red-500" />
                                                      </div>
                                                    </div>
                                                    
                                                    {/* Label */}
                                                    <span className="text-sm text-gray-600 font-medium">{step.label}</span>
                                                    
                                                    {/* Connection line */}
                                                    {index < 3 && (
                                                      <div className="hidden md:block absolute w-full h-0.5 bg-gradient-to-r from-red-100 to-red-200 transform translate-x-1/2 mt-6"></div>
                                                    )}
                                                  </div>
                                                ))}
                                              </div>
                                            </div>
                                  
                                            {/* Message */}
                                            <div className="text-center max-w-md mx-auto">
                                              <div className="bg-white/50 backdrop-blur-sm rounded-2xl p-6 border border-red-100/20">
                                                <p className="text-gray-800 font-medium mb-2">
                                                  {t("analyseFileResultsHere")}
                                                </p>
                                                <p className="text-sm text-gray-600">
                                                    {t("startByUp")}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )}

                                    {/* Analysis content */}
                                    {analysisPerformed && (
                                        <>
                                            <div className="text-center mb-6">
                                                <h1 className="text-xl font-bold text-gray-800">
                                                    {t("yourAnalysisBy")}{" "}
                                                    <span className="text-[#ce3d3d]">{t("bloodFlowAI")}</span>
                                                </h1>
                                                <div className="h-1 w-24 bg-gradient-to-r from-[#ce3d3d] to-[#a31d1d] mx-auto mt-2 rounded-full"></div>
                                            </div>

                                            <div className="flex justify-center border-b mb-4">
                                                <button
                                                    onClick={() => handleTabChange("fullAnalysis")}
                                                    className={`px-4 py-2 ${
                                                        activeTab === "fullAnalysis"
                                                        ? "border-b-2 border-red-500 text-red-500 font-bold"
                                                        : "text-gray-600"
                                                    }`}
                                                >
                                                    Full Analysis
                                                </button>

                                                {/*        
                                                <button
                                                    onClick={() => handleTabChange("screeningAnalysis")}
                                                    className={`px-4 py-2 ${
                                                        activeTab === "screeningAnalysis"
                                                        ? "border-b-2 border-red-500 text-red-500 font-bold"
                                                        : "text-gray-600"
                                                    }`}
                                                >
                                                    Screening Analysis
                                                </button>
                                                */}

                                                <button
                                                    onClick={() => handleTabChange("smartReport")}
                                                    className={`px-4 py-2 ${
                                                        activeTab === "smartReport"
                                                        ? "border-b-2 border-red-500 text-red-500 font-bold"
                                                        : "text-gray-600"
                                                    }`}
                                                >
                                                    Smart Report
                                                </button>

                                                {/*     
                                                <button
                                                    onClick={() => handleTabChange("spatialView")}
                                                    className={`px-4 py-2 ${
                                                        activeTab === "spatialView"
                                                        ? "border-b-2 border-red-500 text-red-500 font-bold"
                                                        : "text-gray-600"
                                                    }`}
                                                >
                                                    Spatial View
                                                </button>  
                                                */}

                                            </div>

                                            {/* Tab contents remain the same */}
                                            {activeTab === "fullAnalysis" && (
                                            <div className="space-y-6">
                                                {loading ? (
                                                <div className="flex items-center justify-center">
                                                    <img src={BloodFlowLogoNL} alt="Logo" className="w-44 animate-spin" />
                                                </div>
                                                ) : (
                                                <>
                                                    {(() => {
                                                    const { score, sections } = parseAnalysisText(displayedText);
                                                    
                                                    return (
                                                        <div className="space-y-4">
                                                            <HealthScoreSection score={score} />    
                                                        

                                                        {/* Analysis Sections */}
                                                        {sections.map((section) => (
                                                        <AnalysisSection
                                                            key={section.id}
                                                            title={section.title}
                                                            content={section.content}  // Add this line
                                                            sectionNumber={section.id}
                                                            isOpen={openSections[section.id] || false}  // Use a new state approach
                                                            onToggle={() => {
                                                                setOpenSections(prev => ({
                                                                    ...prev,
                                                                    [section.id]: !prev[section.id]
                                                                }));
                                                            }}
                                                        >
                                                            <div className="prose max-w-none">
                                                                <ReactMarkdown
                                                                    remarkPlugins={[remarkGfm]}
                                                                    components={{
                                                                        p: ({children}) => (
                                                                            <p className="text-gray-700 leading-relaxed mb-4">{children}</p>
                                                                        ),
                                                                        strong: ({children}) => (
                                                                            <strong className="text-[#ce3d3d] font-semibold">{children}</strong>
                                                                        ),
                                                                        ul: ({children}) => (
                                                                            <ul className="list-disc pl-5 space-y-2 text-gray-700 mb-4">
                                                                            {children}
                                                                            </ul>
                                                                        ),
                                                                        li: ({children}) => (
                                                                            <li className="text-gray-700">{children}</li>
                                                                        ),
                                                                    }}
                                                                >
                                                                    {section.content}
                                                                </ReactMarkdown>
                                                            </div>
                                                        </AnalysisSection>
                                                    ))}
                                                        </div>
                                                    );
                                                    })()}
                                                </>
                                                )}
                                            </div>
                                            )}

                                            {/* Screening Analysis Tab */}
                                            {/*
                                            {activeTab === "screeningAnalysis" && (
                                                <div>
                                                    {analysisScreeningLoading ? (
                                                        <div className="flex items-center justify-center">
                                                            <img
                                                                src={BloodFlowLogoNL}
                                                                alt="Logo"
                                                                className="w-44 animate-spin"
                                                            />
                                                        </div>
                                                    ) : screeningAnalysis ? (
                                                        <div className="typing-effect">
                                                            <ReactMarkdown
                                                                children={screeningAnalysis}
                                                                remarkPlugins={[remarkGfm]}
                                                                components={{
                                                                    strong: ({ children }) => (
                                                                        <strong className="text-[#ce3d3d]">{children}</strong>
                                                                    ),
                                                                    p: ({ children }) => <p className="my-4">{children}</p>,
                                                                }}
                                                            />
                                                        </div>
                                                    ) : (
                                                        <p className="text-center my-4 text-gray-500">
                                                            No screening analysis is available at the moment.
                                                        </p>
                                                    )}
                                                </div>
                                            )}
                                            */}

                                            {/* Smart Report Tab */}
                                            {activeTab === "smartReport" && (
                                                <div>
                                                    {smartReportLoading ? (
                                                        <div className="flex items-center justify-center">
                                                            <img
                                                                src={BloodFlowLogoNL}
                                                                alt="Logo"
                                                                className="w-44 animate-spin"
                                                            />
                                                        </div>
                                                    ) : (
                                                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                                            {smartReport && smartReport.parameters?.length > 0 ? (
                                                                smartReport.parameters.map((param, index) => (
                                                                    <div
                                                                        key={index}
                                                                        className="bg-white p-4 shadow-sm rounded-lg border border-gray-200 flex flex-col relative text-sm"
                                                                    >
                                                                        {/* Status indicator */}
                                                                        <div
                                                                            className={`absolute top-2 right-2 w-3 h-3 rounded-full ${
                                                                                param.status === "high"
                                                                                ? "bg-red-500"
                                                                                : param.status === "low"
                                                                                ? "bg-red-500"
                                                                                : param.status === "ND"
                                                                                ? "bg-blue-500"
                                                                                : "bg-green-500"
                                                                            }`}
                                                                        ></div>

                                                                        {/* Parameter Name */}
                                                                        <h2 className="text-lg font-semibold text-black mb-1">
                                                                            {param.name}
                                                                        </h2>

                                                                        {/* Parameter Value and Range */}
                                                                        <p className="text-sm">
                                                                            <strong>Value:</strong>{" "}
                                                                            <span className="font-semibold">{param.value}</span>
                                                                        </p>
                                                                        <p className="text-sm">
                                                                            <strong>Range:</strong>{" "}
                                                                            <span className="font-semibold">{param.range}</span>
                                                                        </p>

                                                                        {/* Insights */}
                                                                        <p className="mt-2 text-gray-600 text-sm">
                                                                            {param.insight}
                                                                        </p>
                                                                    </div>
                                                                ))
                                                            ) : (
                                                                <p className="col-span-1 md:col-span-2 text-center text-gray-500 text-sm">
                                                                    No smart report available. Please generate a smart report to see the results.
                                                                </p>
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                            )}

                                            {/*
                                            {activeTab === "spatialView" && (
                                                <div>
                                                    {smartReportLoading ? (
                                                        <div className="flex items-center justify-center">
                                                            <img
                                                                src={BloodFlowLogoNL}
                                                                alt="Logo"
                                                                className="w-44 animate-spin"
                                                            />
                                                        </div>
                                                    ) : smartReport ? (
                                                        <div className="py-4">
                                                            <SpatialVisualization smartReport={smartReport} />
                                                        </div>
                                                    ) : (
                                                        <p className="text-center text-gray-500">
                                                            No data available for spatial visualization.
                                                        </p>
                                                    )}
                                                </div>
                                            )}
                                            */}  
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>

                        {/* Footer */}
                        <div className="flex justify-center">
                            <img className="w-32 md:w-40 -mt-4 sm:-mt-6 md:-mt-7" src={BloodFlowLogoNL} alt="/" />
                        </div>
                        <div className="max-w-7xl mx-auto px-6 md:px-12 lg:px-24 py-3 -mt-10 mb-4">
                            <p className="text-center text-sm text-gray-600">
                                © {new Date().getFullYear()} BloodFlow
                            </p>
                        </div>
                    </div>

                    <UpgradeModal 
                        isOpen={showUpgradeModal}
                        onClose={() => setShowUpgradeModal(false)}
                        onUpgrade={() => {
                            setShowUpgradeModal(false);
                            navigate('/dashboard/subscription');
                        }}
                        t={t}
                    />
                </div>
          )    
    );
}

export default Dashboard;