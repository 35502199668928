import React from 'react';
import { AlertTriangle, Crown } from 'lucide-react';

const UpgradeModal = ({ isOpen, onClose, onUpgrade, t }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      {/* Backdrop */}
      <div 
        className="absolute inset-0 bg-black/50 backdrop-blur-sm"
        onClick={onClose}
      />
      
      {/* Modal */}
      <div className="relative bg-white rounded-2xl shadow-xl w-full max-w-md m-4">
        <div className="p-6">
          {/* Header */}
          <div className="flex flex-col items-center mb-6">
            <div className="relative mb-4">
              <div className="absolute inset-0 bg-red-100 rounded-full blur-xl animate-pulse"></div>
              <div className="relative bg-white p-3 rounded-xl shadow-sm border border-red-100">
                <AlertTriangle className="w-8 h-8 text-red-500" />
              </div>
            </div>
            <h2 className="text-xl font-semibold text-gray-900 text-center">
              {t('analysisLimitReached')}
            </h2>
          </div>

          {/* Content */}
          <div className="space-y-4 mb-6">
            <p className="text-gray-600 text-center">
              {t('upgradeToPro')}
            </p>
            
            {/* Pro Features List */}
            <div className="bg-gradient-to-br from-red-50 to-white rounded-xl p-4">
              <div className="flex items-center space-x-2 mb-3">
                <Crown className="w-5 h-5 text-red-500" />
                <span className="font-semibold text-gray-900">{t('proFeatures')}</span>
              </div>
              <ul className="space-y-2">
                <li className="flex items-center text-sm text-gray-600">
                  <div className="w-1.5 h-1.5 bg-red-500 rounded-full mr-2"></div>
                  {t('unlimitedAnalysis')}
                </li>
                <li className="flex items-center text-sm text-gray-600">
                  <div className="w-1.5 h-1.5 bg-red-500 rounded-full mr-2"></div>
                  {t('advHealth')}
                </li>
                <li className="flex items-center text-sm text-gray-600">
                  <div className="w-1.5 h-1.5 bg-red-500 rounded-full mr-2"></div>
                  {t('longitudinal')}
                </li>
                <li className="flex items-center text-sm text-gray-600">
                  <div className="w-1.5 h-1.5 bg-red-500 rounded-full mr-2"></div>
                  {t('BioMarkers')}
                </li>
                <li className="flex items-center text-sm text-gray-600">
                  <div className="w-1.5 h-1.5 bg-red-500 rounded-full mr-2"></div>
                  {t('priority')}
                </li>
              </ul>
            </div>
          </div>

          {/* Buttons */}
          <div className="flex flex-col space-y-3">
            <button
              onClick={onUpgrade}
              className="w-full py-3 bg-gradient-to-r from-red-500 to-red-600 text-white font-semibold rounded-xl hover:from-red-600 hover:to-red-700 transition-all duration-300 transform hover:-translate-y-0.5 shadow-sm hover:shadow-md"
            >
              {t('upgradeToPro')}
            </button>
            <button
              onClick={onClose}
              className="w-full py-3 text-gray-600 font-medium hover:text-gray-800 transition-colors"
            >
              {t('maybeLater')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpgradeModal;