import React from "react";
import { useTranslation } from "react-i18next";

const NewFilePopup = ({ show, onClose, onConfirm, fileName }) => {
  const { t } = useTranslation();

  if (!show) return null;

  return (
    <div className="fixed inset-0 z-50">
      {/* Backdrop */}
      <div className="fixed inset-0 bg-black/30 backdrop-blur-sm transition-opacity" onClick={onClose} />

      {/* Modal */}
      <div className="fixed inset-0 z-50 flex items-center justify-center p-4 sm:p-0">
        <div className="relative transform overflow-hidden rounded-xl bg-white shadow-xl transition-all w-full max-w-md">
          {/* Modal Content */}
          <div className="p-6">
            {/* Modal Header */}
            <div className="flex items-center space-x-4">
              <div className="flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-[#fde8e8]">
                <svg 
                  className="h-6 w-6 text-[#ce3d3d]" 
                  fill="none" 
                  viewBox="0 0 24 24" 
                  strokeWidth="1.5" 
                  stroke="currentColor"
                >
                  <path 
                    strokeLinecap="round" 
                    strokeLinejoin="round" 
                    d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z" 
                  />
                </svg>
              </div>
              <div className="flex-1">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  {t('loadNewFilePopup')}
                </h3>
                <p className="mt-2 text-sm text-gray-500">
                  {t('sentenceNewFilePopup')} 
                  <span className="font-semibold text-gray-700">{fileName}</span> 
                  {t('sentenceNewFilePopup2')}
                </p>
              </div>
            </div>

            {/* Modal Actions */}
            <div className="mt-6 flex flex-col-reverse gap-2 sm:flex-row sm:justify-end">
              <button
                type="button"
                onClick={onClose}
                className="inline-flex w-full justify-center rounded-xl bg-white px-4 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:w-auto transition-all duration-300"
              >
                {t('cancelar')}
              </button>
              <button
                type="button"
                onClick={onConfirm}
                className="inline-flex w-full justify-center rounded-xl bg-gradient-to-r from-[#d73838] to-[#a31d1d] px-4 py-2.5 text-sm font-semibold text-white shadow-sm hover:from-[#a31d1d] hover:to-[#d73838] sm:w-auto transition-all duration-300"
              >
                {t('carregar')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewFilePopup;