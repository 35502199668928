import React from 'react';

const Spinner = () => {
  return (
    <div className="min-h-screen bg-gradient-to-br from-red-50 to-white flex items-center justify-center">
      <div className="relative">
        {/* Main spinner */}
        <div className="w-12 h-12 rounded-full border-4 border-red-100 animate-spin border-t-red-600">
        </div>
        
        {/* Inner glow effect */}
        <div className="absolute top-0 left-0 w-12 h-12 rounded-full animate-pulse bg-red-500/10">
        </div>
      </div>
    </div>
  );
};

export default Spinner;
