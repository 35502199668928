import React, { useState, useEffect } from 'react';

const CircularChart = ({ score }) => {
  const [currentScore, setCurrentScore] = useState(0);

  useEffect(() => {
    // Animate the score from 0 to final value
    const timer = setTimeout(() => {
      setCurrentScore(score);
    }, 100);

    return () => clearTimeout(timer);
  }, [score]);

  const completionAngle = (currentScore / 10) * 360;
  const radius = 80;
  const circumference = 2 * Math.PI * radius;
  const strokeDashoffset = circumference - (completionAngle / 360) * circumference;

  // Get score color and label
  const getScoreDetails = (score) => {
    if (score < 4) {
      return {
        gradientId: 'redGradient',
        label: 'Poor',
        labelColor: '#D83400'
      };
    } else if (score < 6.5) {
      return {
        gradientId: 'orangeGradient',
        label: 'Fair',
        labelColor: '#FF8000'
      };
    } else {
      return {
        gradientId: 'greenGradient',
        label: 'Good',
        labelColor: '#00C834'
      };
    }
  };

  const { gradientId, label, labelColor } = getScoreDetails(score);

  return (
    <svg width="200" height="200" className="transform transition-transform duration-500 hover:scale-105">
      <defs>
        {/* Enhanced gradients with more stops */}
        <linearGradient id="redGradient" x1="0%" y1="0%" x2="100%" y2="100%">
          <stop offset="0%" stopColor="#FF6B6B" />
          <stop offset="50%" stopColor="#D83400" />
          <stop offset="100%" stopColor="#FF3D00" />
          <animate attributeName="x1" values="0%;100%;0%" dur="3s" repeatCount="indefinite" />
          <animate attributeName="y1" values="0%;100%;0%" dur="3s" repeatCount="indefinite" />
        </linearGradient>
        <linearGradient id="orangeGradient" x1="0%" y1="0%" x2="100%" y2="100%">
          <stop offset="0%" stopColor="#FFC000" />
          <stop offset="50%" stopColor="#FF8000" />
          <stop offset="100%" stopColor="#FFA500" />
          <animate attributeName="x1" values="0%;100%;0%" dur="3s" repeatCount="indefinite" />
          <animate attributeName="y1" values="0%;100%;0%" dur="3s" repeatCount="indefinite" />
        </linearGradient>
        <linearGradient id="greenGradient" x1="0%" y1="0%" x2="100%" y2="100%">
          <stop offset="0%" stopColor="#00FF00" />
          <stop offset="50%" stopColor="#00C834" />
          <stop offset="100%" stopColor="#4CAF50" />
          <animate attributeName="x1" values="0%;100%;0%" dur="3s" repeatCount="indefinite" />
          <animate attributeName="y1" values="0%;100%;0%" dur="3s" repeatCount="indefinite" />
        </linearGradient>
        
        {/* Add a glow filter */}
        <filter id="glow">
          <feGaussianBlur stdDeviation="2" result="coloredBlur"/>
          <feMerge>
            <feMergeNode in="coloredBlur"/>
            <feMergeNode in="SourceGraphic"/>
          </feMerge>
        </filter>
      </defs>

      {/* Background arc with gradient */}
      <circle
        cx="100"
        cy="100"
        r={radius}
        fill="none"
        stroke="#f0f0f0"
        strokeWidth="15"
        filter="url(#glow)"
      />

      {/* Score arc with animation */}
      <circle
        cx="100"
        cy="100"
        r={radius}
        fill="none"
        stroke={`url(#${gradientId})`}
        strokeWidth="15"
        strokeDasharray={circumference}
        strokeDashoffset={strokeDashoffset}
        strokeLinecap="round"
        transform="rotate(-90, 100, 100)"
        style={{
          transition: 'stroke-dashoffset 1s ease-in-out',
          filter: 'url(#glow)'
        }}
      >
        <animate
          attributeName="stroke-dashoffset"
          from={circumference}
          to={strokeDashoffset}
          dur="1.5s"
          fill="freeze"
        />
      </circle>

      {/* Central score display */}
      <g transform="translate(100, 100)" textAnchor="middle" dominantBaseline="middle">
        <text
          y="-10"
          fontSize="48"
          fontWeight="bold"
          fill={labelColor}
          style={{ transition: 'fill 0.5s ease' }}
        >
          {currentScore.toFixed(1)}
        </text>
        <text
          y="20"
          fontSize="16"
          fill={labelColor}
          style={{ transition: 'fill 0.5s ease' }}
        >
          {label}
        </text>
      </g>

      {/* Add decorative elements */}
      <g transform="translate(100, 100)">
        {[0, 45, 90, 135, 180, 225, 270, 315].map((angle, i) => (
          <circle
            key={i}
            cx={Math.cos((angle * Math.PI) / 180) * radius}
            cy={Math.sin((angle * Math.PI) / 180) * radius}
            r="2"
            fill="#888"
            opacity="0.5"
          >
            <animate
              attributeName="opacity"
              values="0.2;0.8;0.2"
              dur="2s"
              repeatCount="indefinite"
              begin={`${i * 0.25}s`}
            />
          </circle>
        ))}
      </g>
    </svg>
  );
};

export default CircularChart;