import React, { useState } from 'react';
import BloodFlowLogo from "../../Assets/logoBloodFlowNav.png";
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { setPersistence, browserLocalPersistence, signInWithCustomToken } from "firebase/auth";
import { getDoc, doc } from 'firebase/firestore';
import { db, auth } from "../../firebase";
import { sendEmailVerification } from 'firebase/auth';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';
import { ChevronRight } from "lucide-react";

function LoginPage() {
  const [userEmail, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const { t } = useTranslation();

  const loginUser = async (e) => {
    e.preventDefault();
    setLoading(true);

      try {
        const response = await fetch(`${BASE_URL}/login`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email: userEmail, password }),
        });

        if (!response.ok) {
          throw new Error(t('loginError'));
        }
        
        const data = await response.json();
        const customToken = data.token;

        await setPersistence(auth, rememberMe ? browserLocalPersistence : browserLocalPersistence);
        const userCredential = await signInWithCustomToken(auth, customToken);
        const user = userCredential.user;

        await user.reload();

        if(!user.emailVerified) {
          setLoading(false);
          await sendEmailVerification(user);
          return toast.error(t('registerToastVerifyEmailYet'));
        }

        const userDoc = await getDoc(doc(db, 'users', user.uid));
        const userData = userDoc.data();

        if (userData) {
          toast.success(`${t('bemVindo')} ${userData.username}!`);
    
          if (userData.role === 'fertilitycare') {
            console.log(userData.role);
            navigate('/fertility-care-dashboard', { state: { user: userData } });
          } else if (userData.role === 'followup') {
            navigate('/follow-up-form-fertilitycare', { state: { user: userData } });
          } else {
            navigate('/dashboard', { state: { user: userData } });
          }
        }
      } catch (error) {
        setLoading(false);
        toast.error(t('loginError'));
      }
  };

  const backToHome = () => {
    navigate("/");
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-red-50 to-white flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8 bg-white rounded-2xl shadow-xl p-8">
        <div>
          <img
            onClick={backToHome}
            className="mx-auto h-16 w-auto cursor-pointer hover:opacity-90 transition-opacity"
            src={BloodFlowLogo}
            alt="BloodFlow Logo"
          />
          <h1 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            {t('InicieSessãoNaConta')}
          </h1>
        </div>
        
        <form onSubmit={loginUser} className="mt-8 space-y-6">
          <div className="space-y-4">
            <div>
              <label htmlFor="email" className="sr-only">Email</label>
              <input
                id="email"
                type="text"
                placeholder="Email"
                value={userEmail}
                required
                onChange={(e) => setEmail(e.target.value)}
                className="appearance-none relative block w-full px-4 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-xl focus:outline-none focus:ring-2 focus:ring-red-500 focus:border-transparent transition-all duration-200"
              />
            </div>
            
            <div>
              <label htmlFor="password" className="sr-only">Password</label>
              <input
                id="password"
                type="password"
                placeholder="Password"
                value={password}
                required
                onChange={(e) => setPassword(e.target.value)}
                className="appearance-none relative block w-full px-4 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-xl focus:outline-none focus:ring-2 focus:ring-red-500 focus:border-transparent transition-all duration-200"
              />
            </div>
          </div>

          <button
            type="submit"
            className="group relative w-full flex justify-center py-3 px-4 border border-transparent text-base font-medium rounded-xl text-white shadow-lg transition-all duration-300 hover:shadow-xl hover:-translate-y-0.5"
            disabled={loading}
          >
            <div className="absolute inset-0 bg-gradient-to-r from-red-500 to-red-600 rounded-xl transition-transform duration-300 group-hover:scale-105" />
            <span className="relative z-10 flex items-center justify-center">
              {loading ? (
                <div className="w-5 h-5 border-2 border-white/30 border-t-white rounded-full animate-spin" />
              ) : (
                <>
                  {t('login')}
                  <ChevronRight className="ml-2 size-4 transition-transform group-hover:translate-x-1" />
                </>
              )}
            </span>
          </button>

          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <input
                id="remember-me"
                type="checkbox"
                onChange={(e) => setRememberMe(e.target.checked)}
                className="h-4 w-4 text-red-600 focus:ring-red-500 border-gray-300 rounded cursor-pointer"
              />
              <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                {t('rememberMe')}
              </label>
            </div>
            <RouterLink
              to="/signup"
              className="font-medium text-red-600 hover:text-red-500 transition-colors duration-200"
            >
              {t('CriarConta')}
            </RouterLink>
          </div>
        </form>

        <div className="text-center">
          <RouterLink
            to="/forgotpassword"
            className="font-medium text-sm text-gray-600 hover:text-gray-500 transition-colors duration-200"
          >
            {t('forgotPassword')}
          </RouterLink>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;