import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function FailedPage() {
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <div className="min-h-screen bg-gradient-to-br from-red-50 to-white flex items-center justify-center">
            <div className="text-center max-w-md px-4">
                <h1 className="text-3xl font-bold text-gray-900 mb-4">
                    {t('paymentFailed')}
                </h1>
                <p className="text-gray-600 mb-8">
                    {t('paymentFailedMessage')}
                </p>
                <div className="space-y-4">
                    <button
                        onClick={() => navigate('/#pricing')}
                        className="w-full py-3 px-6 rounded-xl bg-red-500 text-white hover:bg-red-600 transition-colors duration-300"
                    >
                        {t('tryAgain')}
                    </button>
                    <button
                        onClick={() => navigate('/#pricing')}
                        className="w-full py-3 px-6 rounded-xl bg-gray-200 text-gray-700 hover:bg-gray-300 transition-colors duration-300"
                    >
                        {t('contactSupport')}
                    </button>
                </div>
            </div>
        </div>
    );
}